import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit"
import cx from "classnames"

import { Toast } from "../../../components/Toast"
import ProjectComparisonHeader from "./ProjectComparisonHeader"
import ProjectComparisonContent from "./ProjectComparisonContent"
import ProjectComparisonModal from "./ProjectComparisonModal"
import useIsSticky from "../../../hooks/useIsSticky"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import {
  useAccountId,
  useGetCompare,
  useGetComparisonReport,
} from "../../../hooks"
import { genericErrMsg } from "../../../api/auth"
import { ProsConsDataType } from "@/types/comparison"

interface ProjectComparisonProps {
  isMember: boolean | undefined
}

const ProjectComparison = ({ isMember }: ProjectComparisonProps) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { stickyRef, isSticky } = useIsSticky()
  const { comparisonOn, comparison } = useProjectsStore()
  const dialog = useDialogState({ animated: true, modal: false })
  const [comparisonContentOpen, setComparisonContentOpen] =
    useState<boolean>(false)
  const [PDFDownloadLink, setPDFDownloadLink] = useState<string | null>(null)

  const {
    mutateAsync: getCompareReport,
    isPending: isGetCompareReportPending,
  } = useGetComparisonReport(queryClient, accountId, {
    onSuccess: (data: BlobPart) => {
      const blob = new Blob([data], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blob)
      setPDFDownloadLink(url)
    },
    onError: (error: Error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleGetCompareReport = async (data: ProsConsDataType) => {
    await getCompareReport(data)
  }

  const {
    data: compareData,
    mutateAsync: getCompare,
    isPending: isGetComparePending,
  } = useGetCompare(queryClient, accountId, {
    onSuccess: (data: ProsConsDataType) => {
      handleGetCompareReport(data)
    },
    onError: (error: Error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleGetCompare = async () => {
    const programIds: number[] = comparison.map((program) => program.id)

    dialog.show()

    await getCompare(programIds)
  }

  if (!comparisonOn || !isMember) {
    return null
  }

  const handleComparisonContentOpen = () => {
    setComparisonContentOpen((oldState) => !oldState)
  }

  return (
    <>
      <div
        ref={stickyRef}
        className={cx(
          "w-full bg-white border-t-1 border-b-1 border-dusk-100 shadow-dropdown md:shadow-none pt-[18px] mb-5 z-[9]",
          { "sticky top-16 md:top-0 z-10": comparisonOn },
          {
            "md:shadow-dropdown": isSticky,
          }
        )}
      >
        <div className="container max-w-6xl">
          <ProjectComparisonHeader
            comparisonContentOpen={comparisonContentOpen}
            handleComparisonContentOpen={handleComparisonContentOpen}
            handleGetCompare={() => {
              handleGetCompare()
            }}
          />

          <ProjectComparisonContent
            comparisonContentOpen={comparisonContentOpen}
          />
        </div>
      </div>

      <ProjectComparisonModal
        dialog={dialog}
        isFetching={isGetComparePending || isGetCompareReportPending}
        compareData={compareData}
        PDFDownloadLink={PDFDownloadLink}
      />
    </>
  )
}

export default ProjectComparison
