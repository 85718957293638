import cx from "classnames"

import ToggleSwitch from "../../../components/ToggleSwitch"

interface FilterSwitchTypes {
  label: string
  value: boolean
  onChange: () => void
  className?: string
}

const FilterSwitch = ({
  label,
  value,
  onChange,
  className,
}: FilterSwitchTypes) => (
  <div className={cx("flex", className)}>
    <ToggleSwitch
      label={label}
      value={value}
      onChange={onChange}
      className="grow justify-between"
    />
    <span className="block ml-2 w-10 text-base text-charcoal-500 leading-130">
      {value ? "Show" : "Hide"}
    </span>
  </div>
)

export default FilterSwitch
