import { ReactNode, useEffect, useState } from "react"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"
import { DialogStateReturn } from "reakit/Dialog"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

import MapboxGLNotSupported from "../../../components/MapVisualization/MapboxGLNotSupported"
import ProjectMapVisualization from "./ProjectMapVisualization"
import ProjectMapContainer from "./ProjectMapContainer"
import ProjectMapOverlayContainer from "./ProjectMapOverlayContainer"
import ProjectMapParcels from "./ProjectMapParcels"
import ProjectMapSkeleton from "./ProjectMapSkeleton"
import { getViewportFromFeature } from "./helpers"
import { genericErrMsg } from "../../../api/auth"
import { TileURLsTypes } from "@/types/tiles"
import { BadgeDisplayType, IneligibilityReasonsType } from "@/types/constants"
import { ViewportTypes } from "@/types"

interface ProjectMapTypes {
  isLoading: boolean
  isMapError: boolean
  tileData: TileURLsTypes | undefined
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  dialog: DialogStateReturn
  totalAcreage: number | undefined
  isIneligible: boolean
  ineligibilityReasons: IneligibilityReasonsType[] | null | undefined
  eligibleAcres: number
  badgeDisplay: BadgeDisplayType
  eligibilityDialog: DialogStateReturn
  overlay?: ReactNode
  isMember?: boolean
}

const ProjectMap = ({
  isLoading,
  isMapError,
  tileData,
  parcelData,
  dialog,
  totalAcreage,
  isIneligible,
  ineligibilityReasons,
  eligibleAcres,
  badgeDisplay,
  eligibilityDialog,
  overlay,
  isMember,
}: ProjectMapTypes) => {
  const [activeFeature, setActiveFeature] = useState<number | null>(0)
  const [viewport, setViewport] = useState<ViewportTypes | null>(null)

  const handleSetViewport = (newViewport: ViewportTypes | null) => {
    setViewport(newViewport)
  }

  const multipleParcels =
    Array.isArray(parcelData?.features) && parcelData?.features.length > 1

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <ProjectMapContainer dialog={dialog}>
        <ProjectMapSkeleton animate />
      </ProjectMapContainer>
    )
  }

  if (isMapError) {
    return (
      <ProjectMapContainer dialog={dialog}>
        <div className="relative w-full">
          <ProjectMapSkeleton
            message={
              <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded overflow-hidden p-6">
                <div className="w-full h-full">
                  <div className="w-[calc(100%-30px)] md:w-3/4 lg:w-1/2 mx-auto mt-1/4 bg-white p-12">
                    {genericErrMsg}
                  </div>
                </div>
              </div>
            }
          />

          {overlay}
        </div>
      </ProjectMapContainer>
    )
  }

  return (
    <ProjectMapContainer dialog={dialog}>
      <ProjectMapVisualization
        viewport={viewport}
        setViewport={handleSetViewport}
        parcelData={parcelData}
        tileData={tileData}
        activeFeature={activeFeature}
        isIneligible={isIneligible}
        layerSwitchText="Eligibility"
        overlay={overlay}
        isMember={isMember}
      />

      <ProjectMapParcels
        show={isMember !== false && multipleParcels}
        isIneligible={isIneligible}
        parcelData={parcelData}
        activeFeature={activeFeature}
        setActiveFeature={setActiveFeature}
        setViewport={handleSetViewport}
      />

      <ProjectMapOverlayContainer
        isIneligible={isIneligible}
        ineligibilityReasons={ineligibilityReasons}
        eligibleAcres={eligibleAcres}
        totalAcreage={totalAcreage}
        isMember={isMember}
        badgeDisplay={badgeDisplay}
        eligibilityDialog={eligibilityDialog}
      />
    </ProjectMapContainer>
  )
}

export default ProjectMap
