import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons"

import { RecommendationType } from "@/types/comparison"

interface ProjectComparisonModalRecommendationProps {
  recommendation: RecommendationType | null
}

const ProjectComparisonModalRecommendation = ({
  recommendation,
}: ProjectComparisonModalRecommendationProps) => {
  if (!recommendation?.reason) {
    return <div /> // DEV: Return empty div, so we don't break the grid layout.
  }

  return (
    <div className="text-charcoal-500 text-sm leading-[150%] tracking-0.14 border border-dusk-50 rounded px-3 py-2 mt-4">
      <p>
        <FontAwesomeIcon
          icon={faStar}
          className="inline-block w-4 h-4 text-[#F29D13] ml-[3px] mr-[7px]"
        />
        <span className="font-semibold">Recommended based on your goals</span>
      </p>

      <p className="mt-2">{recommendation?.reason}</p>
    </div>
  )
}

export default ProjectComparisonModalRecommendation
