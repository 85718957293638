import cx from "classnames"

import SelectMenu, { OptionType } from "../../../components/SelectMenu"
import SearchField from "../../../components/SearchField"
import ProjectFilters from "./ProjectFilters"
import ProjectComparisonToggleSwitch from "../ProjectComparison/ProjectComparisonToggleSwitch"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { useFirstPageRedirect } from "../../../hooks/useFirstPageRedirect"
import useIsSticky from "../../../hooks/useIsSticky"
import { ProjectDataTypes } from "../../../types/program"
import { AccountTypes } from "../../../types/account"
import { DropdownOptionsType } from "../types"

interface ProjectFiltersBarProps {
  projectsData: ProjectDataTypes[]
  account: AccountTypes | undefined
  dropdownOptions: DropdownOptionsType[]
  sortBy: string
  handleSortBy: (value: OptionType) => void
  isMember: boolean | undefined
}

const ProjectFiltersBar = ({
  projectsData,
  account,
  dropdownOptions,
  sortBy,
  handleSortBy,
  isMember,
}: ProjectFiltersBarProps) => {
  const handleFirstPageRedirect = useFirstPageRedirect()
  const { comparisonOn, searchProjects, setSearchProjects } = useProjectsStore()
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")
  const { stickyRef, isSticky } = useIsSticky()

  const handleSearch = (value: string) => {
    setSearchProjects(value)
    handleFirstPageRedirect()
  }

  return (
    <div
      ref={stickyRef}
      className={cx(
        "container max-w-6xl bg-white shadow-dropdown md:shadow-none",
        { "sticky top-16 md:top-0 z-[9]": !comparisonOn || !isMember },
        {
          "border-b-1 border-dusk-100 md:shadow-dropdown": isSticky,
        }
      )}
    >
      <div className="flex flex-wrap lg:flex-nowrap flex-row items-center gap-3 xs:gap-4 pt-6 pb-6">
        <ProjectFilters
          filteredNumber={projectsData.length}
          handleFirstPageRedirect={handleFirstPageRedirect}
        />

        <ProjectComparisonToggleSwitch disabled={!account?.is_member} />

        <SelectMenu
          className="min-w-[252px] shrink-0"
          menuClassName="min-w-[252px]"
          options={dropdownOptions}
          label="Sort by"
          showSelected={true}
          selectedOption={sortBy}
          setSelectedOption={handleSortBy}
          onSelect={handleFirstPageRedirect}
          placement="bottom-end"
          gutter={10}
        />

        <SearchField
          id="search-projects"
          initialValue={searchProjects}
          onChange={handleSearch}
          placeholder="Search by keyword or program type"
          className="max-w-[343px] grow-0 ml-auto"
          show={!isMaxWidthLg}
        />
      </div>
    </div>
  )
}

export default ProjectFiltersBar
