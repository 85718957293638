import { DialogStateReturn } from "reakit"

import ScoreCardItem from "./ScoreCardItem"
import { ScoreData } from "@/types/property"
import ScoreCardHeading from "./ScoreCardHeading"
import ScoreCardSubHeading from "./ScoreCardSubHeading"
import { SCORE_CARDS } from "./constants"
import ScoreCardDialogActions from "./ScoreCardDialogActions"
import { ProjectDataTypes } from "@/types/program"
import ScoreCardDialogPrograms from "./ScoreCardDialogPrograms"
import ScoreCardStateComparison from "./ScoreCardStateComparison"
import { getScoreRating } from "./helpers"

export interface ScoreCardDialogContentProps {
  activeScoreCardItem: string | null
  scoreData: ScoreData | null
  potentialIncomePrograms: ProjectDataTypes[] | null
  dialog: DialogStateReturn
  isMember: boolean | undefined
}

const ScoreCardDialogContent = ({
  activeScoreCardItem,
  scoreData,
  potentialIncomePrograms,
  dialog,
  isMember,
}: ScoreCardDialogContentProps) => {
  if (!activeScoreCardItem || !scoreData) {
    return null
  }

  const activeScoreData = scoreData[activeScoreCardItem]
  const activeScoreCard = SCORE_CARDS[activeScoreCardItem]
  const title = activeScoreCard.heading
  const maxScore = activeScoreData.max_score || 0
  const maxScoreRatingString = getScoreRating(maxScore).toLowerCase()

  return (
    <div>
      <p className="text-charcoal-500 font-body leading-130 tracking-0.32">
        Review the scorecard details for {title} in your Natural Capital
        Portfolio.{" "}
      </p>

      <div className="w-full border border-dusk-50 rounded-lg mt-4 pt-4 px-6 pb-3">
        <ScoreCardItem
          currentScore={activeScoreData?.current_score}
          maxScore={activeScoreData?.max_score}
          category={activeScoreCardItem}
          hasScoreBarLegend
        />

        <ScoreCardStateComparison
          isMember={isMember}
          currentScore={activeScoreData?.current_score}
          comparisonData={activeScoreData?.comparison}
        />

        <ScoreCardHeading className="pt-4">Potential</ScoreCardHeading>
        <ScoreCardSubHeading
          text={activeScoreCard.potential_template(
            maxScoreRatingString,
            maxScore
          )}
          className="mt-2"
        />

        <ScoreCardHeading className="mt-4">Actions</ScoreCardHeading>
        <ScoreCardSubHeading text={activeScoreCard.actions} className="mt-2" />

        <ScoreCardHeading className="mt-4">Tradeoffs</ScoreCardHeading>
        <ScoreCardSubHeading
          text={activeScoreCard.tradeoffs}
          className="mt-2"
        />

        <ScoreCardHeading className="mt-4">Programs</ScoreCardHeading>
        <ScoreCardSubHeading text={activeScoreCard.programs} className="mt-2" />

        <ScoreCardDialogPrograms
          dialog={dialog}
          programs={potentialIncomePrograms}
        />

        <ScoreCardDialogActions
          title={title}
          activeScoreCardItem={activeScoreCardItem}
        />
      </div>
    </div>
  )
}

export default ScoreCardDialogContent
