import { MouseEventHandler } from "react"
import { CloseButton } from "../../../components/CloseButton"
import {
  useProjectsStore,
  initialState,
} from "../../../stores/useProjectsStore"

interface ProjectFiltersDrawerHeaderProps {
  handleFirstPageRedirect: () => void
  handleClose: MouseEventHandler<HTMLButtonElement>
}

const ProjectFiltersDrawerHeader = ({
  handleFirstPageRedirect,
  handleClose,
}: ProjectFiltersDrawerHeaderProps) => {
  const { setMultiState } = useProjectsStore()

  return (
    <div className="flex bg-dusk-50 p-4">
      <button
        type="button"
        className="link focus:outline-none"
        onClick={() => {
          setMultiState({
            govtProjectsOn: initialState.govtProjectsOn,
            ineligibleProjectsOn: initialState.ineligibleProjectsOn,
            hasLandownerCost: initialState.hasLandownerCost,
            termLengthFilter: initialState.termLengthFilter,
            paymentTypes: initialState.paymentTypes,
          })
          handleFirstPageRedirect()
        }}
      >
        Clear
      </button>

      <h4 className="grow text-center text-lg font-bold leading-130 mr-2.5">
        Filters
      </h4>

      <CloseButton
        className="shrink-0 ml-4 rounded focus:outline-none text-charcoal-500 font-bold"
        aria-label="Close filters"
        onClick={handleClose}
      />
    </div>
  )
}

export default ProjectFiltersDrawerHeader
