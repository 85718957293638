import cx from "classnames"

export interface ScoreCardScoreBarLegendProps {
  hasScoreBarLegend: boolean
  currentScoreHeight: number
  maxScorePosition: number
  hidePotentialScore?: boolean
}

const ScoreCardScoreBarLegend = ({
  hasScoreBarLegend,
  currentScoreHeight,
  maxScorePosition,
  hidePotentialScore = false,
}: ScoreCardScoreBarLegendProps) => {
  if (!hasScoreBarLegend) {
    return null
  }

  return (
    <div
      className="relative w-[70px] h-[86px]"
      data-testid="score-card-score-bar-legend"
    >
      {/* DEV: Max */}
      <div
        className={cx(
          "absolute -top-px -left-2 w-7 h-px bg-dusk-200",
          "after:content-['Max'] after:absolute after:left-[30px] after:bottom-px after:h-3 after:text-xs after:text-dusk-900 after:opacity-30 after:tracking0.36"
        )}
      />

      {/* DEV: Potential */}
      {!hidePotentialScore && (
        <div
          data-testid="score-card-score-bar-legend-potential"
          className={cx(
            "absolute w-5 h-[2px] bg-bottom bg-repeat-x",
            "after:content-['Potential'] after:absolute after:left-[22px] after:-top-px after:h-3 after:text-xs after:text-dusk-900 after:leading-3 after:tracking0.36"
          )}
          style={{
            bottom: `${maxScorePosition}%`,
            backgroundImage:
              "url('data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%222%22 viewBox=%220%200%2024%202%22 fill=%22none%22><path d=%22M1 1H23%22 stroke=%22%234E5563%22 stroke-linecap=%22square%22 stroke-linejoin=%22bevel%22 stroke-dasharray=%222%202%22/></svg>')",
          }}
        />
      )}

      {/* DEV: Your Score */}
      <div
        data-testid="score-card-score-bar-legend-your-score"
        className={cx(
          "absolute -left-2 w-7 h-px bg-dusk-900",
          "after:content-['Your_Score'] after:absolute after:left-[30px] after:-bottom-2 after:h-5 after:text-xs after:text-dusk-900 after:leading-3 after:tracking0.36"
        )}
        style={{
          bottom: `${currentScoreHeight / 2}%`,
        }}
      />
    </div>
  )
}

export default ScoreCardScoreBarLegend
