import { ReactNode } from "react"
import { Navigate, useLocation, useSearchParams } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { Spinner } from "../../components/Spinner"
import {
  useAccount,
  useAccountId,
  useCreateMembership,
  useProfile,
  useAccountProperty,
  useAccountUrlPrefix,
  useIsMultiAccount,
  useAccountInterest,
} from "../../hooks"
import { useAccessToken } from "../../stores"
import { VerifyEmailInnerContent } from "../../sections/VerifyEmailContent"
import AcceptTocModal from "../../sections/AcceptTocModal"
import { AccountProperty } from "../../types/property"
import { Profile } from "../../types"
import { AccountTypes } from "@/types/account"
import { AccountInterestTypes } from "@/types/accountInterest"

export interface MainLayoutProps {
  children: ReactNode
  isLoading?: boolean | boolean[]
  contentBg?: string
  loader?: ReactNode
  className?: string
}

const MainLayout = ({
  children,
  isLoading,
  contentBg,
  loader,
  className,
}: MainLayoutProps) => {
  const bgClass = `bg-${contentBg}`
  const location = useLocation()
  const queryClient = useQueryClient()
  const accessToken = useAccessToken()
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const accountUrlPrefix = useAccountUrlPrefix()
  const [searchParams] = useSearchParams()
  const isLoggedIn = !!accessToken

  const queryEnabled =
    isLoggedIn &&
    ((isMultiAccount && accountId !== "_single") || !isMultiAccount)

  // Fetch data
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient, {
    enabled: isLoggedIn,
  })

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId, {
    enabled: queryEnabled,
  })

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    enabled: queryEnabled,
  })

  const { data: accountInterestData, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId, {
      enabled: queryEnabled,
    })

  // Handle membership creation
  const membershipSuccessToken =
    searchParams.get("checkout-success")?.slice(2, -1) || ""

  const { status: membershipStatus } = useCreateMembership(
    accountId,
    membershipSuccessToken,
    {
      enabled: !!accountId && !!membershipSuccessToken,
    }
  )

  const createMembershipIsLoading =
    membershipSuccessToken && membershipStatus === "pending"

  // Determine loading state
  const isLoadingArr = [
    profileIsLoading,
    accountIsLoading,
    propertyIsLoading,
    createMembershipIsLoading,
    accountInterestIsLoading,
    ...(Array.isArray(isLoading) ? isLoading : [isLoading]),
  ]

  if (isLoadingArr.some(Boolean)) {
    return (
      loader ?? (
        <div className="flex-auto flex items-center justify-center">
          <Spinner />
        </div>
      )
    )
  }

  // Redirection Conditions
  const shouldRedirectToPropertyBoundaries =
    account &&
    property &&
    !property.bounds?.coordinates?.length &&
    !location.pathname.includes("/property-boundaries") &&
    location.pathname !== "/logout"

  const shouldRedirectToOnboardingGoals =
    account &&
    accountInterestData &&
    !accountInterestData.has_answered_all_questions &&
    !location.pathname.includes("/property-boundaries") &&
    !location.pathname.includes("/onboarding/goals") &&
    location.pathname !== "/logout"

  if (shouldRedirectToPropertyBoundaries) {
    return (
      <Navigate
        replace
        to={
          isMultiAccount
            ? `${accountUrlPrefix}/settings/property-boundaries`
            : "/onboarding/property-boundaries"
        }
      />
    )
  }

  if (shouldRedirectToOnboardingGoals) {
    return <Navigate replace to={`${accountUrlPrefix}/onboarding/goals`} />
  }

  if (!isLoggedIn) {
    return (
      <div className={cx("flex-auto flex flex-col", bgClass, className)}>
        {children}
      </div>
    )
  }

  return (
    <div className={cx("flex-auto flex flex-col", bgClass, className)}>
      <VerifyEmailInnerContent profile={profile as Profile}>
        {children}
      </VerifyEmailInnerContent>

      <AcceptTocModal profile={profile} />
    </div>
  )
}

export default MainLayout
