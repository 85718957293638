import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import { useFormikContext, Form, Formik, FormikHelpers } from "formik"
import * as yup from "yup"
import {
  DialogDisclosure,
  DialogStateReturn,
  useDialogState,
} from "reakit/Dialog"

import { Modal } from "../../components/Modal"
import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { usePartnersStore } from "../../stores/usePartnersStore"
import { ButtonPair } from "../../components/ButtonPair"
import PartnersAccountContainer from "./PartnersAccountContainer"
import { useUpdateAccountProjectReachout } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { EligibleLeadTypes, ReachoutsCounterTypes } from "@/types/partners"

interface PartnerEligibleLeadsContactTypes {
  userFirstName: string | null | undefined
  reachoutsCounter: ReachoutsCounterTypes | undefined
}

interface ReachoutModalTypes {
  dialog: DialogStateReturn
  reachoutsCounter: ReachoutsCounterTypes | undefined
}

interface PartnerEligibleLeadsFormValuesTypes {
  profile_id: number
  sender: string
  message: string
}

interface ErrorResponseData {
  message?: string
}

const validationSchema = yup.object().shape({
  message: yup.string().required("Please fill out this field."),
})

const ReachoutModal = ({ dialog, reachoutsCounter }: ReachoutModalTypes) => {
  const { submitForm, isSubmitting } = useFormikContext()

  return (
    <Modal
      header="Confirm invitation"
      aria-label="Reachout dialog"
      dialog={dialog}
    >
      <p>This action will use one Invitation for the month.</p>
      <p>
        You have {reachoutsCounter?.reachouts_remaining} invitation(s) left.
      </p>

      <ButtonPair
        className="mt-6"
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <SubmitButton
            isSubmitting={isSubmitting}
            onClick={() => {
              submitForm()
            }}
            disabled={
              reachoutsCounter && reachoutsCounter?.reachouts_remaining < 1
            }
            {...primaryProps}
          >
            Send
          </SubmitButton>
        )}
        secondary={
          <button type="button" onClick={dialog.hide}>
            Cancel
          </button>
        }
      />
    </Modal>
  )
}

const PartnerEligibleLeadsContact = ({
  userFirstName,
  reachoutsCounter,
}: PartnerEligibleLeadsContactTypes) => {
  const queryClient = useQueryClient()

  const { activeRow } = usePartnersStore()

  const dialog = useDialogState({ animated: true })

  const { mutateAsync: updatePartnersProjectEligibleLead } =
    useUpdateAccountProjectReachout(queryClient, activeRow?.id as number, {
      onSuccess: () => {
        dialog.hide()
        Toast.success("Message has been sent successfully.")
      },
      onError: (error: AxiosError) => {
        Toast.error(
          (error?.response?.data as ErrorResponseData)?.message || genericErrMsg
        )
      },
    })

  const handleUpdate = async (
    values: PartnerEligibleLeadsFormValuesTypes,
    { resetForm }: FormikHelpers<PartnerEligibleLeadsFormValuesTypes>
  ) => {
    await updatePartnersProjectEligibleLead(values)
    resetForm()
  }

  return (
    <PartnersAccountContainer className="relative overflow-hidden">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        Contact {activeRow?.first_name}
      </h4>

      <p className="label leading-18px font-normal mt-4">
        Message landowners and invite them to join your program. They'll get a
        notification to accept or decline the invite to connect.{" "}
      </p>

      <Formik
        initialValues={{
          profile_id: (activeRow as EligibleLeadTypes)?.profile_id,
          sender: userFirstName as string,
          message: "",
        }}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleUpdate}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <WrappedInput
              label="Sender (Optional)"
              labelClass="leading-18px text-charcoal-500 mt-4 mb-1"
              placeholder="Sender"
              type="text"
              name="sender"
              className="border-charcoal-50"
            />

            <WrappedInput
              label="Message"
              labelClass="leading-18px text-charcoal-500 mt-4 mb-1"
              aria-label="Message"
              name="message"
              as="textarea"
              className="border-charcoal-50"
              maxLength={750}
            />

            <DialogDisclosure
              {...dialog}
              className="btn2 btn2-primary btn2-block font-semibold mt-4"
              disabled={!(formikProps.isValid && formikProps.dirty)}
            >
              Send
            </DialogDisclosure>

            <ReachoutModal
              dialog={dialog}
              reachoutsCounter={reachoutsCounter}
            />
          </Form>
        )}
      </Formik>
    </PartnersAccountContainer>
  )
}

export default PartnerEligibleLeadsContact
