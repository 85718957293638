import { useEffect, useRef, useState } from "react"

const useIsSticky = () => {
  const stickyRef = useRef<HTMLDivElement>(null)

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!stickyRef.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([event]) => setIsSticky(event.intersectionRatio < 1),
      { threshold: [1], rootMargin: "-1px 0px 0px 0px" }
    )
    observer.observe(stickyRef.current)

    return () => observer.disconnect()
  }, [stickyRef])

  return { stickyRef, isSticky }
}

export default useIsSticky
