import { WheelEvent } from "react"
import { DateTime } from "luxon"
import { toHtml, icon, IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { flatten, find } from "lodash"
import bbox from "@turf/bbox"
import { parseDms } from "dms-conversion/dist/esm/dms"

import {
  LANDOWNER_STATUS,
  LANDOWNER_STATUS_MESSAGE,
  PROJECT_BADGE_TYPES,
  PROJECT_SORTING,
  DEADLINE_TYPE,
  PROJECT_TYPES,
} from "./constants"
import {
  stateOptions,
  stateAbbreviations,
  conus,
  stateAbbreviationPattern,
  stateNamePattern,
} from "../fixtures"
import PLACEHOLDER from "../images/project-details.webp"

import CAT_FILTER_TREE_PLANTING from "../images/category-filter-tree-planting.png"
import CAT_FILTER_RENEWABLE_INFRASTRUCTURE from "../images/category-filter-renewable-energy.png"
import CAT_FILTER_TIMBER from "../images/category-filter-timber-harvest.png"
import CAT_FILTER_RECREATION from "../images/category-filter-recreation.png"
import CAT_FILTER_HARVEST_DEFERRAL from "../images/category-filter-harvest-deferral.png"
import CAT_FILTER_FOREST_CARBON from "../images/category-filter-renewable-energy.png"
import CAT_FILTER_WATER from "../images/category-filter-water.png"
import CAT_FILTER_BIODIVERSITY from "../images/category-filter-biodiversity.png"
import CAT_FILTER_WILDFIRE from "../images/category-filter-wildfire-risk.png"
import CAT_FILTER_REGEN_AG from "../images/category-filter-regenerative-agriculture.png"
import CAT_FILTER_ADVISORY_SERVICES from "../images/category-filter-advisory-services.png"
import { Location as RRDLocation } from "react-router"
import {
  BadgeDisplayType,
  ProgramCategoryType,
  USStateAbbrType,
} from "@/types/constants"
import {
  PaymentTypeKeysType,
  ProgramType,
  ProjectListTypes,
} from "@/types/program"
import { publicApiType } from "react-horizontal-scrolling-menu"
import { AttestationsType } from "@/types/attestations"
import { ProgramTypes } from "@/sections/ProjectListing/types"
import { FilterType } from "@/sections/ProjectListing/CategoryFilter/CategoryFilter"
import { FeatureType, ViewportTypes } from "@/types"
import { OptionType } from "@/components/SelectMenu"

export const anyToDateTime = (input: string | number | Date | DateTime) => {
  if (DateTime.isDateTime(input)) {
    return input
  } else if (typeof input === "string") {
    return DateTime.fromISO(input)
  } else if (typeof input === "number") {
    return DateTime.fromMillis(input)
  } else if (input instanceof Date) {
    return DateTime.fromJSDate(input)
  } else {
    throw new Error(
      `Expected Luxon DateTime, ISO timestamp, milliseconds, or Date, but received: ${
        input as string
      }`
    )
  }
}

export const getQueryParam = (
  location: Location | RRDLocation<any>,
  key: string
) => {
  const searchParams = new URLSearchParams(location.search)
  // when no param, returns null
  return searchParams.get(key)
}

export const getEnrollmentDeadline = (
  type: string | undefined | null,
  deadline: string | undefined | null
) => {
  const types = {
    specific_date: `Enroll by ${DateTime.fromISO(
      deadline as string
    ).toLocaleString({
      month: "short",
      day: "numeric",
      year: "numeric",
    })}`,
    open_enrollment: "Open enrollment",
    pending_interest: "Coming soon",
  }
  return types[type as keyof typeof types]
}

export const getProjectType = (
  type: ProgramCategoryType,
  variant: string = "long"
) => {
  const projectType = PROJECT_TYPES[type] || PROJECT_TYPES.other

  return projectType[variant as keyof typeof projectType] || projectType.long
}

export const getProjectBadgeType = (status: BadgeDisplayType) => {
  const badges = {
    [LANDOWNER_STATUS.eligible]: PROJECT_BADGE_TYPES.primary,
    [LANDOWNER_STATUS.not_interested]: PROJECT_BADGE_TYPES.info,
    [LANDOWNER_STATUS.request_information]: PROJECT_BADGE_TYPES.neutral,
    [LANDOWNER_STATUS.ineligible]: PROJECT_BADGE_TYPES.error,
    [LANDOWNER_STATUS.in_progress]: PROJECT_BADGE_TYPES.neutral,
    [LANDOWNER_STATUS.under_contract]: PROJECT_BADGE_TYPES.primary,
    [LANDOWNER_STATUS.information_needed]: PROJECT_BADGE_TYPES.warning,
    default: PROJECT_BADGE_TYPES.neutral,
  }

  return badges[status] || badges.default
}

export const getProjectBadgeText = (type: BadgeDisplayType) => {
  const interests = {
    [LANDOWNER_STATUS.eligible]: LANDOWNER_STATUS_MESSAGE.eligible,
    [LANDOWNER_STATUS.not_interested]: LANDOWNER_STATUS_MESSAGE.not_interested,
    [LANDOWNER_STATUS.request_information]:
      LANDOWNER_STATUS_MESSAGE.request_information,
    [LANDOWNER_STATUS.ineligible]: LANDOWNER_STATUS_MESSAGE.ineligible,
    [LANDOWNER_STATUS.determining_eligibility]:
      LANDOWNER_STATUS_MESSAGE.determining_eligibility,
    [LANDOWNER_STATUS.under_contract]: LANDOWNER_STATUS_MESSAGE.under_contract,
    [LANDOWNER_STATUS.information_needed]:
      LANDOWNER_STATUS_MESSAGE.information_needed,
    default: LANDOWNER_STATUS_MESSAGE.determining_eligibility,
  }
  return interests[type] || interests.default
}

export const getProjectEarningsNullValue = (type: ProgramCategoryType) => {
  const projectType = PROJECT_TYPES[type]
  return projectType?.earningsNullLabel || "Earnings vary"
}

const sortProjectsBy: Record<
  string,
  (a: ProgramType, b: ProgramType) => number
> = {
  [PROJECT_SORTING.RECENTLY_ADDED]: (
    a: ProgramType,
    b: ProgramType
  ): number => {
    const dateA = new Date(a?.updated_at).getTime()
    const dateB = new Date(b?.updated_at).getTime()
    return dateB - dateA
  },
  [PROJECT_SORTING.MOST_POPULAR]: (a: ProgramType, b: ProgramType): number => {
    return b.num_accounts_interested - a.num_accounts_interested
  },
  [PROJECT_SORTING.VERIFIED]: (a: ProgramType, b: ProgramType): number => {
    return b.is_verified ? (a.is_verified ? 0 : 1) : a.is_verified ? -1 : 0
  },
  [PROJECT_SORTING.ELIGIBILITY]: (a: ProgramType, b: ProgramType): number => {
    // DEV: assign each badge_display a numeric value in descending order
    const badgeVal = (badge: BadgeDisplayType) => {
      const badges = {
        [LANDOWNER_STATUS.under_contract]: 7,
        [LANDOWNER_STATUS.request_information]: 6,
        [LANDOWNER_STATUS.eligible]: 5,
        [LANDOWNER_STATUS.information_needed]: 4,
        [LANDOWNER_STATUS.determining_eligibility]: 3,
        [LANDOWNER_STATUS.not_interested]: 2,
        [LANDOWNER_STATUS.ineligible]: 1,
        default: 3,
      }
      return badges[badge] || badges.default
    }

    // DEV: then sort by badge display
    if (badgeVal(a.badge_display) > badgeVal(b.badge_display)) {
      return -1
    }
    if (badgeVal(a.badge_display) < badgeVal(b.badge_display)) {
      return 1
    }

    // DEV: finally, check if gov vs non-gov and place gov last
    if (a.is_government_program < b.is_government_program) return -1
    if (a.is_government_program > b.is_government_program) return 1

    return 0
  },
  [PROJECT_SORTING.ENROLLMENT_DEADLINE]: (
    a: ProgramType,
    b: ProgramType
  ): number => {
    const dateA = new Date(a?.enrollment_deadline_date as string).getTime()
    const dateB = new Date(b?.enrollment_deadline_date as string).getTime()

    // DEV: assign each enrollment_deadline_type a numeric value in descending order
    const deadlineTypeVal = {
      [DEADLINE_TYPE.specific_date]: 3,
      [DEADLINE_TYPE.open_enrollment]: 2,
      [DEADLINE_TYPE.pending_interest]: 1,
    }

    // DEV: then sort by deadline type
    if (
      deadlineTypeVal[a.enrollment_deadline_type] >
      deadlineTypeVal[b.enrollment_deadline_type]
    ) {
      return -1
    }
    if (
      deadlineTypeVal[a.enrollment_deadline_type] <
      deadlineTypeVal[b.enrollment_deadline_type]
    ) {
      return 1
    }

    // DEV: then check if gov vs non-gov and place gov last
    if (a.is_government_program < b.is_government_program) return -1
    if (a.is_government_program > b.is_government_program) return 1

    // DEV: finally, sort by date
    if (
      a.enrollment_deadline_type === DEADLINE_TYPE.specific_date &&
      b.enrollment_deadline_type === DEADLINE_TYPE.specific_date
    ) {
      return dateA - dateB
    }

    return 0
  },
  [PROJECT_SORTING.POTENTIAL_EARNINGS]: (
    a: ProgramType,
    b: ProgramType
  ): number => {
    return b.potential_earnings - a.potential_earnings
  },
}

const extractProjectStates = (project: ProgramType): USStateAbbrType[] => {
  const requirements = project.overview_information?.eligibility_requirements
  let states: (USStateAbbrType | USStateAbbrType[] | null)[] = []

  // Check if requirements are defined
  if (requirements) {
    Object.values(requirements).forEach((req) => {
      if (typeof req === "string") {
        // first look for most common: state abbreviations
        const stateList = req.match(stateAbbreviationPattern) // assume this pattern is defined
        states.push(stateList as unknown as USStateAbbrType[])

        if (!stateList) {
          // then look for full state name, when it's one.
          const stateName = req.match(stateNamePattern) // assume this pattern is defined
          if (stateName) {
            const foundState = find(stateOptions, (stateOption) => {
              return stateOption[1].toLowerCase() === stateName[0].toLowerCase()
            })
            if (foundState) {
              states.push(foundState[0])
            }
          }
        }

        if (req.includes("48 states")) {
          states = conus
        }
      }
    })
  }

  // Flatten the array and filter out any falsy values (like null)
  const newStates = flatten(states).filter(Boolean) as USStateAbbrType[]

  // If no states found, return all 50 states
  return states.length > 0 ? newStates : stateAbbreviations // assume stateAbbreviations is defined
}

export const getProjectsData = (
  data: ProjectListTypes | undefined
): ProgramType[] => {
  const projectsData = data?.project_data?.map((project) => {
    const accountProject = data.account_project_data.find(
      (accProject) => project.id === accProject.project_id
    )
    const newProject = {
      ...project,
      image_url:
        project?.image_url?.replace(/^(.+?\.(png|svg)).*$/i, "$1") ||
        PLACEHOLDER,
      not_interested_reasons: accountProject?.not_interested_reasons,
      not_interested_reason_additional:
        accountProject?.not_interested_reason_additional,
      landowner_status: accountProject?.status,
      badge_display: accountProject?.badge_display,
      is_eligible: accountProject?.is_eligible ?? null,
      ineligibility_reasons: accountProject?.ineligibility_reasons ?? undefined,
      eligible_acres: accountProject?.eligible_acres ?? null,
      states: extractProjectStates(project),
    }
    return newProject
  }) as ProgramType[]

  return projectsData ?? []
}

export const filterProjectsData = (
  projectsData: ProgramType[],
  sortBy: OptionType,
  category: ProgramCategoryType | null,
  govtProgramsOn: boolean,
  ineligibleProjectsOn: boolean,
  hasLandownerCost: boolean,
  termLengthFilter: [number, number],
  paymentTypes: Record<PaymentTypeKeysType, boolean>,
  searchProjects: string
) => {
  let filteredData = projectsData?.sort(sortProjectsBy[sortBy as string])
  let checkedPaymentTypes: PaymentTypeKeysType[] = []

  if (paymentTypes) {
    checkedPaymentTypes = Object?.entries(paymentTypes)
      .filter((paymentTerm) => paymentTerm[1])
      .map((paymentTerm) => paymentTerm[0] as PaymentTypeKeysType)
  }

  if (govtProgramsOn === false) {
    filteredData = filteredData.filter(
      (project) => project.is_government_program === false
    )
  }

  if (ineligibleProjectsOn === false) {
    filteredData = filteredData.filter(
      (project) => project.is_eligible !== false
    )
  }

  if (hasLandownerCost === false) {
    filteredData = filteredData.filter(
      (project) =>
        project.lo_cost === null ||
        project.lo_cost === undefined ||
        project.lo_cost === 0
    )
  }

  if (termLengthFilter && termLengthFilter[0] > 0) {
    filteredData = filteredData.filter(
      (project) =>
        project.term === null ||
        project.term === undefined ||
        Number(project.term) >= termLengthFilter[0]
    )
  }

  if (termLengthFilter && termLengthFilter[1] < 100) {
    filteredData = filteredData.filter(
      (project) =>
        project.term === null ||
        project.term === undefined ||
        Number(project.term) <= termLengthFilter[1]
    )
  }

  if (checkedPaymentTypes?.length > 0) {
    filteredData = filteredData.filter(
      ({ payment_types }) =>
        payment_types?.some(({ type }) => checkedPaymentTypes.includes(type))
    )
  }

  if (category) {
    filteredData = filteredData.filter((project) => project.type === category)
  }

  if (searchProjects.length > 0) {
    filteredData = filteredData.filter((project) => {
      const searchTerm = searchProjects.toLowerCase()
      return (
        project.name.toLowerCase().includes(searchTerm) ||
        project.developer.toLowerCase().includes(searchTerm) ||
        project.description_short.toLowerCase().includes(searchTerm) ||
        project.description_long.toLowerCase().includes(searchTerm) ||
        project.states
          .map((state) => {
            const stateAbbreviation = state.toLowerCase()
            const stateName = find(
              stateOptions,
              (stateOption) => stateOption[0] === state.toUpperCase()
            )?.[1]?.toLowerCase()

            return [stateAbbreviation, stateName]
          })
          .flat()
          .filter(Boolean)
          .join(",")
          .includes(searchTerm)
      )
    })
  }

  return filteredData.sort(
    (a: ProgramType, b: ProgramType) =>
      Number(b.is_sponsored) - Number(a.is_sponsored)
  )
}

export function onWheel(apiObj: publicApiType, ev: WheelEvent) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

  if (isThouchpad) {
    ev.stopPropagation()
    return
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext()
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev()
  }
}

export const getProjectData = (
  data: ProjectListTypes | undefined,
  projectsData: ProgramType[],
  projectId: number
): ProgramType | undefined => {
  const accountProjectData = data?.account_project_data?.find(
    (project) => project.project_id === projectId
  )
  const projectData = projectsData?.find((project) => project.id === projectId)

  if (projectData === undefined) {
    return undefined
  }

  return {
    ...projectData,
    badge_display: accountProjectData?.badge_display,
    has_service_provider_coverage:
      accountProjectData?.has_service_provider_coverage,
  } as ProgramType
}

export const getStackableProjectsData = (
  stackableIds: number[] | undefined,
  projectsData: ProgramType[]
) => {
  const stackableProjectsData = stackableIds?.map(
    (projectId) => projectsData?.find((project) => project.id === projectId)
  )
  // endpoint returns all ids but we only care about the top 5
  return stackableProjectsData?.filter(Boolean).slice(0, 5) ?? []
}

export const allAttestationsHaveValue = (
  attestationsData: AttestationsType | undefined
) =>
  Object.values(attestationsData as AttestationsType).length > 0 &&
  Object.values(attestationsData as AttestationsType).every((attestation) =>
    Object.prototype.hasOwnProperty.call(attestation, "value")
  )

export const getSVGURI = (faIcon: IconDefinition, color: string) => {
  const abstract = icon(faIcon).abstract[0]
  if (color && abstract.children) {
    abstract.children[0].attributes.fill = color
  }

  return `data:image/svg+xml;base64,${window.btoa(toHtml(abstract))}`
}

export const secondsToMinutes = (totalSeconds: number) => {
  const minutes = Math.ceil(totalSeconds / 60)
  const unit = minutes > 1 ? "minutes" : "minute"

  return `${minutes} ${unit}`
}

export const getProjectTypeFilters = (
  projectTypes: ProgramTypes | undefined,
  category: ProgramCategoryType | null
): FilterType[] => {
  const images = {
    [PROJECT_TYPES.tree_planting.id]: CAT_FILTER_TREE_PLANTING,
    [PROJECT_TYPES.renewable_infrastructure.id]:
      CAT_FILTER_RENEWABLE_INFRASTRUCTURE,
    [PROJECT_TYPES.timber.id]: CAT_FILTER_TIMBER,
    [PROJECT_TYPES.recreation.id]: CAT_FILTER_RECREATION,
    [PROJECT_TYPES.harvest_deferral.id]: CAT_FILTER_HARVEST_DEFERRAL,
    [PROJECT_TYPES.forest_carbon.id]: CAT_FILTER_FOREST_CARBON,
    [PROJECT_TYPES.water.id]: CAT_FILTER_WATER,
    [PROJECT_TYPES.biodiversity.id]: CAT_FILTER_BIODIVERSITY,
    [PROJECT_TYPES.wildfire.id]: CAT_FILTER_WILDFIRE,
    [PROJECT_TYPES.regen_ag.id]: CAT_FILTER_REGEN_AG,
    [PROJECT_TYPES.other.id]: CAT_FILTER_ADVISORY_SERVICES,
  }

  const projectTypeFilters = projectTypes?.project_types.map((projectType) => {
    return {
      id: projectType,
      tag: PROJECT_TYPES[projectType].long,
      selected: projectType === category ? true : false,
      image: images[projectType],
    }
  })

  // DEV: Sort projectTypeFilters based on the order of keys in PROJECT_TYPES
  projectTypeFilters?.sort(
    (a, b) =>
      Object.keys(PROJECT_TYPES).indexOf(a.id) -
      Object.keys(PROJECT_TYPES).indexOf(b.id)
  )

  return projectTypeFilters as FilterType[]
}

export function getTimePassedText(dateString: string) {
  const currentDate = DateTime.now()
  const givenDate = DateTime.fromISO(dateString)
  const isSameDate = givenDate.hasSame(currentDate, "day")
  const isYesterday = givenDate.hasSame(currentDate.minus({ days: 1 }), "day")

  if (isSameDate) {
    return "Today"
  } else if (isYesterday) {
    return "Yesterday"
  } else {
    const diffDays = Math.floor(
      currentDate.diff(givenDate, "days").toObject().days ?? 0
    )
    const diffMonths = Math.floor(
      currentDate.diff(givenDate, "months").toObject().months ?? 0
    )
    const diffYears = Math.floor(
      currentDate.diff(givenDate, "years").toObject().years ?? 0
    )

    if (diffDays === 1) {
      return "1 day ago"
    } else if (diffDays > 1 && diffDays < 30) {
      return `${diffDays} days ago`
    } else if (diffMonths === 1) {
      return "1 month ago"
    } else if (diffMonths > 1 && diffMonths < 12) {
      return `${diffMonths} months ago`
    } else if (diffYears === 1) {
      return "1 year ago"
    } else {
      return `${diffYears} years ago`
    }
  }
}

export const transformBounds = (bounds: {
  type?: string
  coordinates: any
}): FeatureType[] => {
  // return empty array if user has no bounds drawn
  if (!bounds?.coordinates) return []
  return bounds.coordinates.map((coordinateGroup: any, index: any) => {
    return {
      id: `feature-${index}`,
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: coordinateGroup,
        type: "Polygon",
      },
    }
  })
}

export const DEFAULT_ZOOM_LEVEL = 12

export const getViewportFromFeatures = (
  features: FeatureType[]
): ViewportTypes | null => {
  if (features?.length === 0) return null

  const bounds = bbox({
    type: "FeatureCollection",
    features: features,
  })

  const [minLng, minLat, maxLng, maxLat] = bounds

  const longitude = (minLng + maxLng) / 2
  const latitude = (minLat + maxLat) / 2

  // DEV: Calculate the zoom level based on the bounding box
  const deltaLng = maxLng - minLng
  const deltaLat = maxLat - minLat
  const zoom = Math.min(
    Math.floor(
      Math.min(
        Math.log2((360 * (window.innerWidth / 512)) / deltaLng),
        Math.log2((170 * (window.innerHeight / 512)) / deltaLat)
      )
    ),
    20
  )

  return {
    longitude,
    latitude,
    zoom: zoom || DEFAULT_ZOOM_LEVEL,
  }
}

export function areArraysEqual(firstArray: string[], secondArray: number[]) {
  return firstArray.every(
    (item, index) => item.toString() === secondArray[index].toString()
  )
}

export function isDMS(value: string) {
  const addressSplittedByComma = value.split(",").map((item) => item.trim())
  const addressAsDD = addressSplittedByComma.map(parseDms)

  // if any item is NaN after parsing, `value` is neither DMS nor DD
  if (addressAsDD.some((item) => Number.isNaN(item))) {
    return false
  }

  // value is DMS if parsed array is not equal to `value`
  // (i.e. value array after splitted by comma, since `value` is string)
  return !areArraysEqual(addressSplittedByComma, addressAsDD)
}

export function formatPrice(price: number = 0): string {
  // Extract dollars
  const dollars = Math.floor(price / 100)
  // Format the price as a string without cents
  return `$${dollars}`
}

// returns just the correct ordinal suffix (i.e. 'st' or 'rd') for the given number
export function getOrdinalSuffix(num: number): string {
  const suffixes = ["th", "st", "nd", "rd"]
  const v = num % 100
  return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]
}
