import { Link } from "react-router"

import { AcreageToolTip } from "../../components/AcreageToolTip"
import { shortenAcreage } from "../../utils"
import {
  buildAccountAbsoluteUrl,
  useAccountId,
  useAccountUrlPrefix,
} from "../../hooks"
import { LAND_GOALS } from "../../shared/constants"
import { AccountInterestTypes } from "@/types/accountInterest"

interface DashboardAccountInfoTypes {
  acreage: number
  accountInterestData?: AccountInterestTypes
}

const DashboardAccountInfo = ({
  acreage,
  accountInterestData,
}: DashboardAccountInfoTypes) => {
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const accountUrl = buildAccountAbsoluteUrl(accountId)
  const landGoals = [accountInterestData?.primary_land_goal || ""]
    .concat(accountInterestData?.other_land_goals || [])
    .filter((v) => v !== "")

  return (
    <div className=" bg-white shadow rounded-md px-6 pt-6 pb-4 mb-4 last:mb-0">
      <h4 className="text-overline mb-3">Property</h4>

      <div className="mb-6">
        <div className="big-stat mr-16">
          <span className="big-stat-number">{shortenAcreage(acreage)}</span>

          <span className="big-stat-unit">
            total acres <AcreageToolTip />
          </span>
        </div>
      </div>

      <div className="border-cloud mb-4">
        <Link
          to={{
            pathname: `${accountUrlPrefix}/settings/property-boundaries`,
          }}
          state={{ from: { pathname: accountUrl } }}
          className="view-property-boundaries link"
        >
          View Property Boundaries
        </Link>
      </div>

      <hr className="border-cloud mb-4" />

      {accountInterestData?.has_answered_all_questions && (
        <>
          <h4 className="text-overline mb-3">Your Top Land Goals</h4>

          <div className="mb-6">
            <ul className="leading-130 tracking-0.32 mt-3">
              {landGoals.map((goal) => (
                <li className="mb-1" key={goal}>
                  {LAND_GOALS[goal as keyof typeof LAND_GOALS]}
                </li>
              ))}
            </ul>

            <div className="mt-3">
              <Link
                to={`${accountUrlPrefix}/onboarding/goals`}
                state={{ fromDashboard: true }}
                className="dashboard-edit-goals mt-4 link"
              >
                Edit Land Goals
              </Link>
            </div>
          </div>

          <hr className="border-cloud mb-4" />
        </>
      )}

      <div>
        <Link
          to={`${accountUrlPrefix}/settings`}
          className="edit-account-settings link"
        >
          Edit Account Settings
        </Link>
      </div>
    </div>
  )
}

export default DashboardAccountInfo
