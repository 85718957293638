import { RefObject } from "react"
import cx from "classnames"

import ProjectFiltersDrawerHeader from "./ProjectFiltersDrawerHeader"
import ProjectFiltersDrawerFooter from "./ProjectFiltersDrawerFooter"
import ProjectFiltersDrawerAccordion from "./ProjectFiltersDrawerAccordion"
import ProjectFiltersDrawerFilterSwitches from "./ProjectFiltersDrawerFilterSwitches"

interface ProjectFiltersDrawerProps {
  overlayRef: RefObject<HTMLDivElement | null>
  filtersRef: RefObject<HTMLDivElement | null>
  filtersOpen: boolean
  handleClose: () => void
  handleFirstPageRedirect: () => void
  handleTermLength: (e: [number, number]) => void
  filteredNumber: number
}

const ProjectFiltersDrawer = ({
  overlayRef,
  filtersRef,
  filtersOpen,
  handleClose,
  handleFirstPageRedirect,
  handleTermLength,
  filteredNumber,
}: ProjectFiltersDrawerProps) => (
  <div
    ref={overlayRef}
    className={cx(
      "fixed flex flex-row-reverse inset-0 pointer-events-none transition-all duration-300 ease-in-out z-[2147483643]",
      {
        "!pointer-events-auto bg-gray-500/75 opacity-100": filtersOpen,
      }
    )}
    onClick={(e) => {
      if (e.target === overlayRef.current) {
        handleClose()
      }
    }}
  >
    <div
      ref={filtersRef}
      className={cx(
        "relative w-full flex flex-col self-start max-w-340 h-full bg-white -right-full",
        "transition-[right] duration-300 ease-in-out",
        {
          "!right-0": filtersOpen,
        }
      )}
      aria-label="Filters"
    >
      <ProjectFiltersDrawerHeader
        handleFirstPageRedirect={handleFirstPageRedirect}
        handleClose={handleClose}
      />

      <div className="p-4 overflow-auto">
        <ProjectFiltersDrawerFilterSwitches
          handleFirstPageRedirect={handleFirstPageRedirect}
        />

        <ProjectFiltersDrawerAccordion
          handleTermLength={handleTermLength}
          handleFirstPageRedirect={handleFirstPageRedirect}
        />
      </div>

      <ProjectFiltersDrawerFooter
        handleClose={handleClose}
        filteredNumber={filteredNumber}
      />
    </div>
  </div>
)

export default ProjectFiltersDrawer
