import ProjectVerificationBadge from "../ProjectVerificationBadge"

export interface ProjectCardVerificationBadgeProps {
  showMinimalInfo: boolean
  isVerified: boolean
}

const ProjectCardVerificationBadge = ({
  showMinimalInfo,
  isVerified,
}: ProjectCardVerificationBadgeProps) => {
  if (showMinimalInfo) {
    return null
  }

  return (
    <div className="verification-badge-card">
      <ProjectVerificationBadge
        isVerified={isVerified}
        includeLabel={false}
        disclosureClassName="absolute top-4 right-4 [&_img]:w-7 [&_img]:h-[27px]"
        popoverClassName="z-[1]"
      />
    </div>
  )
}

export default ProjectCardVerificationBadge
