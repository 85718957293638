import { useEffect, useRef, ReactNode } from "react"
import { Dialog, DialogStateReturn } from "reakit/Dialog"
import cx from "classnames"

import { CloseButton } from "./CloseButton"
import ModalBackdrop from "./ModalBackdrop"

interface ModalTypes {
  header?: ReactNode
  children: ReactNode
  className?: string
  headerClass?: string
  closeMode?: "hide" | "destroy"
  dismissable?: boolean
  onDismiss?: () => void
  hideOnEsc?: boolean
  hideOnClickOutside?: boolean
  dialog: DialogStateReturn
  [key: string]: any
}

export const Modal = ({
  header = "",
  children,
  className,
  headerClass,
  closeMode = "hide", // hide | destroy
  dismissable = true,
  onDismiss,
  hideOnEsc = dismissable,
  hideOnClickOutside = dismissable,
  dialog, // dialog state as returned by `useDialogState`
  ...props // remaining props are passed through to Reakit `Dialog`
}: ModalTypes) => {
  const isDialogOnScreen = dialog.visible || dialog.animating

  // Detect when modal is dismissed (and any exit animation has finished)
  // https://github.com/ariakit/ariakit/issues/877
  const previousOnScreenRef = useRef(isDialogOnScreen)
  useEffect(() => {
    if (
      previousOnScreenRef.current !== isDialogOnScreen &&
      !isDialogOnScreen &&
      onDismiss
    ) {
      onDismiss()
    }
    previousOnScreenRef.current = isDialogOnScreen

    // Explicitly omit onDismiss
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOnScreen])

  return (
    <ModalBackdrop dialog={dialog}>
      <Dialog
        {...dialog}
        className={cx("st-dialog", className)}
        hideOnEsc={hideOnEsc}
        hideOnClickOutside={hideOnClickOutside}
        {...props}
      >
        {closeMode === "hide" || isDialogOnScreen ? (
          <div>
            <div className={cx("flex items-center mb-4", headerClass)}>
              <h2 className="flex-auto text-xl leading-6">{header}</h2>

              {dismissable && (
                <CloseButton
                  className="shrink-0 ml-4 rounded focus:outline-none text-charcoal-500 font-bold"
                  aria-label="Close modal"
                  onClick={dialog.hide}
                />
              )}
            </div>

            <div>{children}</div>
          </div>
        ) : null}
      </Dialog>
    </ModalBackdrop>
  )
}
