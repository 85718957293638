import { Link } from "react-router"
import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import {
  Tooltip as ReakitTooltip,
  TooltipArrow,
  TooltipReference,
  useTooltipState,
} from "reakit/Tooltip"
import cx from "classnames"

import { Toast } from "../../components/Toast"
import PremiumCheckmarkList from "../../components/PremiumCheckmarkList "
import { useAccount, useAccountId, useCreateStripeSession } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { formatPrice } from "../../shared/utils"
import { AccountTypes } from "@/types/account"

const AdvisorSignupPay = () => {
  const tooltip = useTooltipState()
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: "nca_onetime",
    }

    await createStripeSession(postData)
  }

  if (accountIsLoading) {
    return null
  }

  const price = formatPrice(account?.subscription_pricing.nca_onetime)
  const isHighAcreage = account?.subscription_pricing.nca_onetime === null

  return (
    <div className="w-full max-w-[816px] mx-auto">
      <h2 className="text-xl md:text-3xl text-autumn text-center font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px] mt-7">
        $3,000 in income opportunities or your money back
      </h2>

      <div className="w-full border border-dusk-50 rounded py-4 pr-8 pl-7 mt-7 mb-3">
        <div className="md:flex md:gap-x-5">
          <div className="grow">
            <div className="flex gap-2 items-center">
              <h4 className="text-sm font-medium font-overline uppercase leading-[150%] tracking-[1.68px] flex-0">
                What you'll get
              </h4>

              <div className="h-0 border-b border-[#C0C8CE] grow" />
            </div>

            <PremiumCheckmarkList className="mt-6" />
          </div>

          <div
            className={cx(
              "flex flex-col w-full shrink-0 bg-sand border border-charcoal-50 rounded pt-5 px-9 pb-4 mt-5 md:mt-0",
              isHighAcreage ? "md:w-[300px]" : "md:w-[230px]"
            )}
          >
            <p className="text-xl text-charcoal-500 text-center md:text-left leading-[140%] tracking-[0.28px] md:pl-[10px]">
              {isHighAcreage
                ? "Due to the size of your property, your NCX Advisor will quote your Expert Plan after your consultation."
                : "Pay now and an NCX expert will reach out to schedule a call."}
            </p>
            {!isHighAcreage && (
              <p className="text-2xl text-charcoal-500 text-center font-medium leading-[124%] tracking-0.48 pt-3 mt-auto">
                {price}
              </p>
            )}

            <TooltipReference {...tooltip} className="ring-0">
              <button
                className={cx(
                  "member-widget-join-now nca-purchase md:h-[45px] btn2 btn2-primary font-semibold btn2-block mx-auto mt-3 mb-0",
                  isHighAcreage ? "max-w-50" : "max-w-40"
                )}
                onClick={() => {
                  handleStripeSubmit()
                }}
                disabled={account?.role !== "admin"}
              >
                {isHighAcreage ? "Book Consultation" : "Pay Now"}
              </button>
            </TooltipReference>

            {account?.role !== "admin" ? (
              <ReakitTooltip {...tooltip} className="st-tooltip">
                <div className="text-sm max-w-sm p-3 st-tooltip-inner">
                  <TooltipArrow {...tooltip} />
                  Only admins can subscribe.
                </div>
              </ReakitTooltip>
            ) : null}
          </div>
        </div>
      </div>

      <Link
        to="/"
        className="member-widget-dashboard-link text-lg text-leaf leading-[140%] tracking-0.36"
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  )
}

export default AdvisorSignupPay
