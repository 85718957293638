import { useState } from "react"
import { Link } from "react-router"

import ContentCard from "../../components/ContentCard"
import CountdownTimer from "../../components/CountdownTimer"
import MembershipBannerHeading from "./MembershipBanner/MembershipBannerHeading"
import MembershipBannerText from "./MembershipBanner/MembershipBannerText"
import { useAccountUrlPrefix } from "../../hooks"
import useFeatureFlags from "../../hooks/useFeatureFlags"
import { FEATURE_FLAGS } from "../../shared/constants"

interface MembershipBannerProps {
  isMember: boolean | undefined
}

const MembershipBanner = ({ isMember }: MembershipBannerProps) => {
  const [isTimerComplete, setIsTimerComplete] = useState<boolean>(false)
  const accountUrlPrefix = useAccountUrlPrefix()

  const { flagEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.homepage_hero_banner_20241127
  )

  const handleSetIsTimerComplete = () => {
    setIsTimerComplete(true)
  }

  if (isMember) {
    return null
  }

  return (
    <ContentCard className="shadow md:col-span-3" roundedMd>
      <MembershipBannerHeading
        isTimerComplete={isTimerComplete}
        isFlagEnabled={!!clientReady && !!flagEnabled}
      />

      <MembershipBannerText isTimerComplete={isTimerComplete} />

      <CountdownTimer
        date={"2025-01-01T00:00:00"}
        className="block text-dusk text-overline mt-4"
        onTimerComplete={handleSetIsTimerComplete}
      />

      <Link
        to={`${accountUrlPrefix}/become-a-member`}
        className="membership-banner-join-btn btn2 btn2-primary font-semibold mt-4"
        state={{ scrollToTop: true }}
      >
        Join now
      </Link>
    </ContentCard>
  )
}

export default MembershipBanner
