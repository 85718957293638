import cx from "classnames"

import ToggleSwitch from "../../../components/ToggleSwitch"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import ProjectComparisonToggleSwitchLock from "./ProjectComparisonToggleSwitchLock"

interface ProjectComparisonToggleSwitchProps {
  disabled: boolean
}

const ProjectComparisonToggleSwitch = ({
  disabled,
}: ProjectComparisonToggleSwitchProps) => {
  const { comparisonOn, setComparisonOn } = useProjectsStore()

  return (
    <div
      className={cx(
        "flex items-center gap-2 shrink-0 border border-dusk-50 rounded p-1.75",
        { "cursor-not-allowed": disabled }
      )}
    >
      <ToggleSwitch
        label="Compare programs"
        value={comparisonOn}
        onChange={() => {
          setComparisonOn(!comparisonOn)
        }}
        disabled={disabled}
      />

      <ProjectComparisonToggleSwitchLock disabled={disabled} />
    </div>
  )
}

export default ProjectComparisonToggleSwitch
