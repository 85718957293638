import { useEffect, useRef, useState } from "react"
import cx from "classnames"

import ProjectComparisonCard from "./ProjectComparisonCard"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import useMediaQuery from "../../../hooks/useMediaQuery"

interface ProjectComparisonContentProps {
  comparisonContentOpen: boolean
}

const ProjectComparisonContent = ({
  comparisonContentOpen,
}: ProjectComparisonContentProps) => {
  const { comparison } = useProjectsStore()
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")
  const contentRef = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    if (contentRef.current && (comparisonContentOpen || !isMaxWidthLg)) {
      setMaxHeight(contentRef.current.scrollHeight)
    } else {
      setMaxHeight(0)
    }
  }, [comparisonContentOpen, comparison, isMaxWidthLg])

  return (
    <div
      className="w-full overflow-hidden h-auto transition-max-height duration-200 ease-in-out"
      style={{
        maxHeight: maxHeight,
        height: 368, // DEV: height of 3 stacked cards
      }}
    >
      <div
        ref={contentRef}
        className={cx("flex flex-col lg:grid lg:grid-cols-3 gap-4 lg:gap-8", {
          "pb-[30px]": comparison.length > 0,
        })}
      >
        {comparison.map((program) => (
          <ProjectComparisonCard
            key={program.id}
            id={program.id}
            image={program.image_url}
            name={program.name}
          />
        ))}
      </div>
    </div>
  )
}

export default ProjectComparisonContent
