import cx from "classnames"

import DOMPurify from "dompurify"

export interface ScoreCardSubHeadingProps {
  text: string
  className?: string
}

const ScoreCardSubHeading = ({
  text,
  className = "",
}: ScoreCardSubHeadingProps) => (
  <p
    className={cx(
      "text-dusk-900 text-sm text-left font-body font-medium leading-[140%] tracking-[0.42px]",
      className
    )}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
  />
)

export default ScoreCardSubHeading
