import cx from "classnames"

import ProjectComparisonModalNoRecommended from "./ProjectComparisonModalNoRecommended"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { RecommendationType } from "@/types/comparison"

interface ProjectComparisonModalFooterProps {
  PDFDownloadLink: string | null
  recommendation: RecommendationType | undefined
  className?: string
}

const ProjectComparisonModalFooter = ({
  PDFDownloadLink,
  recommendation,
  className,
}: ProjectComparisonModalFooterProps) => {
  const { comparison } = useProjectsStore()

  const heapTrackingClass = `download-compare-report-for-programs-${comparison
    .map((program) => program.id)
    .join("-")}`

  const showNoRecommended = false

  return (
    <footer className={cx("flex md:mt-[18px]", className)}>
      <ProjectComparisonModalNoRecommended
        show={showNoRecommended}
        reason={recommendation?.reason}
      />

      <a
        href={PDFDownloadLink || ""}
        download="report.pdf"
        className={cx(heapTrackingClass, "btn2 btn2-primary ml-auto")}
      >
        Download PDF
      </a>
    </footer>
  )
}

export default ProjectComparisonModalFooter
