import { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import {
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

interface FilterAccordionItemTypes {
  heading: string
  children: ReactNode
}

const FilterAccordionItem = ({
  heading,
  children,
}: FilterAccordionItemTypes) => (
  <AccordionItem className="border-t border-charcoal-50 py-6">
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionItemState>
          {({ expanded }) => (
            <span className="flex justify-between text-base text-charcoal-500 font-bold leading-130 tracking-0.32">
              {heading}
              <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
            </span>
          )}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className="mb-0 pt-2.5">{children}</AccordionItemPanel>
  </AccordionItem>
)

export default FilterAccordionItem
