import { Dispatch, SetStateAction } from "react"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

import ProjectMapParcelsList from "./ProjectMapParcelsList"
import ProjectMapParcelsBtn from "./ProjectMapParcelsBtn"
import { handleNextFeature, handlePrevFeature } from "./helpers"
import { ViewportTypes } from "@/types"

type FeaturePrevNextClickType = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: (newViewport: ViewportTypes | null) => void,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => void

interface ProjectMapParcelsTypes {
  show: boolean
  isIneligible: boolean
  parcelData: FeatureCollection<Geometry, Properties> | null | undefined
  activeFeature: number | null
  setActiveFeature: Dispatch<SetStateAction<number | null>>
  setViewport: (newViewport: ViewportTypes | null) => void
  onNextFeatureClick?: FeaturePrevNextClickType
  onPrevFeatureClick?: FeaturePrevNextClickType
  enableEligibility?: boolean
}

const ProjectMapParcels = ({
  show,
  isIneligible,
  parcelData,
  activeFeature = 0,
  setActiveFeature,
  setViewport,
  onNextFeatureClick,
  onPrevFeatureClick,
  enableEligibility = true,
}: ProjectMapParcelsTypes) => {
  if (!show || !parcelData) {
    return null
  }

  const handlePrevClick = () => {
    onPrevFeatureClick
      ? onPrevFeatureClick(setActiveFeature, setViewport, parcelData)
      : handlePrevFeature(setActiveFeature, setViewport, parcelData)
  }

  const handleNextClick = () => {
    onNextFeatureClick
      ? onNextFeatureClick(setActiveFeature, setViewport, parcelData)
      : handleNextFeature(setActiveFeature, setViewport, parcelData)
  }

  return (
    <div className="bg-white w-full flex flex-col-reverse lg:max-w-[244px] lg:flex-col lg:border lg:border-l-0 lg:border-charcoal-50 lg:rounded-e">
      <ProjectMapParcelsList
        parcelData={parcelData}
        enableEligibility={enableEligibility}
        isIneligible={isIneligible}
        setViewport={setViewport}
        activeFeature={activeFeature}
        setActiveFeature={setActiveFeature}
      />

      <div className="w-full h-[60px] flex gap-4 border lg:border-0 lg:border-b-0 lg:border-t-1 border-charcoal-50 px-4 py-2 mt-0 mb-0 xl:mb-0 xl:mt-auto">
        <ProjectMapParcelsBtn onClick={handlePrevClick} icon={faChevronLeft} />
        <ProjectMapParcelsBtn onClick={handleNextClick} icon={faChevronRight} />
      </div>
    </div>
  )
}

export default ProjectMapParcels
