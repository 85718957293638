import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import { Link, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip"

import BecomeAMemberWidgetOption from "./BecomeAMemberWidgetOption"
import BecomeAMemberToggle from "./BecomeAMemberToggle"
import BecomeAMemberWidgetOptionList from "./BecomeAMemberWidgetOptionList"
import CornerRibbon from "../../components/CornerRibbon"
import PremiumCheckmarkList from "../../components/PremiumCheckmarkList "
import { Toast } from "../../components/Toast"
import { useAccount, useAccountId, useCreateStripeSession } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { formatPrice } from "../../shared/utils"
import { AccountTypes } from "@/types/account"

const BecomeAMemberWidget = () => {
  const tooltip = useTooltipState()
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const location = useLocation()
  const [isPremium, setIsPremium] = useState<boolean>(false)
  const [isMonthly, setIsMonthly] = useState<boolean>(true)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: isPremium || !isMonthly ? "basic_annual" : "basic_monthly",
      addon_keys: isPremium ? ["nca_onetime"] : [],
    }

    await createStripeSession(postData)
  }

  const handleIsMontly = () => {
    setIsMonthly((oldIsMonthly) => !oldIsMonthly)
  }

  useEffect(() => {
    if (location.state?.includeAdvisor || account?.is_member) {
      setIsPremium(true)
    }
  }, [account?.is_member, location.state])

  if (accountIsLoading || !account) {
    return null
  }

  const priceYearly = formatPrice(account?.subscription_pricing.basic_annual)
  let price = formatPrice(account?.subscription_pricing.basic_monthly)

  if (isPremium) {
    price = formatPrice(
      account?.subscription_pricing.nca_onetime +
        account?.subscription_pricing.basic_annual
    )
  } else if (!isMonthly) {
    price = formatPrice(account?.subscription_pricing.basic_annual / 12)
  }

  const isHighAcreage = account?.subscription_pricing.nca_onetime === null

  return (
    <div className="w-full max-w-[916px] mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <BecomeAMemberWidgetOption
          heading="Basic"
          subheading="The Membership Every Landowner Needs"
          subheadingClass="w-[calc(100%-50px)]"
          enabled={!account?.is_member}
          active={!isPremium}
          onClick={() => {
            !account?.is_member ? setIsPremium(false) : null
          }}
        >
          <CornerRibbon text="Most Popular" />

          <BecomeAMemberWidgetOptionList />
        </BecomeAMemberWidgetOption>

        <BecomeAMemberWidgetOption
          heading="Premium"
          subheading="Includes 1 year of Basic, plus:"
          active={isPremium}
          onClick={() => {
            setIsPremium(true)
          }}
        >
          <PremiumCheckmarkList className="mt-5" />
        </BecomeAMemberWidgetOption>
      </div>

      <BecomeAMemberToggle
        show={!isPremium}
        selection={isMonthly}
        setSelection={handleIsMontly}
      />

      <h3 className="text-charcoal-500 text-2xl font-medium leading-[124%] tracking-0.48 mt-4">
        Price: {price}
        {!isPremium ? "/month" : ""}
        {!isMonthly && !isPremium ? (
          <span className="inline-block text-sm leading-[140%] font-body font-normal tracking-[0.28px] ml-2">
            billed {priceYearly}/yearly
          </span>
        ) : null}
      </h3>

      <p className="text-dusk text-lg leading-[140%] tracking-0.36 mt-1">
        Membership renews {!isMonthly || isPremium ? "annually" : "monthly"} at
        the Basic Member rate and can be canceled anytime.
      </p>

      {isHighAcreage && isPremium && (
        <p className="text-dusk text-lg leading-[140%] tracking-0.36 mt-1">
          Due to the size of your property, your NCX Advisor will quote your
          Expert Plan after your consultation.
        </p>
      )}

      <TooltipReference {...tooltip} className="ring-0">
        <button
          className="member-widget-join-now max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          onClick={() => {
            handleStripeSubmit()
          }}
          disabled={account?.role !== "admin"}
        >
          Join Now
        </button>
      </TooltipReference>

      {account?.role !== "admin" ? (
        <ReakitTooltip {...tooltip} className="st-tooltip">
          <div className="text-sm max-w-sm p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} />
            Only admins can subscribe.
          </div>
        </ReakitTooltip>
      ) : null}

      <Link
        to="/"
        className="member-widget-dashboard-link inline-block text-lg text-leaf leading-[140%] tracking-0.36 mt-3"
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  )
}

export default BecomeAMemberWidget
