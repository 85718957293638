import { useState } from "react"
import { useDialogState } from "reakit"

import ContentCard from "../../../components/ContentCard"
import PropertyMap from "../../PropertyMap"
import LandText from "./LandText"
import LandHeader from "./LandHeader"
import LandCoverStats from "./LandCoverStats"
import LandMapOverlayDialog from "./LandMapOverlayDialog"
import { useHeapTrackOnView } from "../../../hooks/useHeapTrackOnView"
import { Profile } from "@/types"
import { AccountProperty } from "@/types/property"

interface LandProps {
  accountId: string
  profile: Profile
  property: AccountProperty
}

const Land = ({ accountId, profile, property }: LandProps) => {
  const mapRef = useHeapTrackOnView({
    event: "componentview-dashboard-map",
    observerOptions: { threshold: 0.5 },
  })
  const dialog = useDialogState({ modal: false })
  const [visible, setVisible] = useState<boolean>(true)

  const handleSetVisible = () => {
    setVisible((oldVisible) => !oldVisible)
  }

  return (
    <ContentCard className="shadow" roundedMd>
      <LandHeader firstName={profile.first_name} acreage={property.acreage} />

      <LandCoverStats landCoverDetails={property.landcover_data} />

      <div ref={mapRef}>
        <PropertyMap
          accountId={accountId}
          enableZoomPan
          overlay={
            <LandMapOverlayDialog
              dialog={dialog}
              visible={visible}
              setVisible={handleSetVisible}
              dataNotReady={property.landcover_data === null}
            />
          }
        />
      </div>

      <LandText />
    </ContentCard>
  )
}

export default Land
