import { Link } from "react-router"
import cx from "classnames"

import { formatCurrency } from "../../../utils"
import { useAccountUrlPrefix } from "../../../hooks"
import { ProjectDataTypes } from "@/types/program"
import { ProsConsEntryType, RecommendationType } from "@/types/comparison"
import ProjectComparisonModalRecommendation from "./ProjectComparisonModalRecommendation"

interface ProjectComparisonModalCardProps {
  program: ProjectDataTypes
  prosConsEntryData: ProsConsEntryType | undefined
  recommendation: RecommendationType | null
  className?: string
}

const ProjectComparisonModalCard = ({
  program,
  prosConsEntryData,
  recommendation,
  className,
}: ProjectComparisonModalCardProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  const termText = program?.term
    ? `${program?.term} year term`
    : "Term length varies"

  const potentialEarningsText = program?.potential_earnings
    ? `${formatCurrency(
        program.potential_earnings
      )} per acre for the total contract term length`
    : "Earnings vary"

  return (
    <Link
      to={`${accountUrlPrefix}/programs/${program.id}`}
      className={cx(
        "grid grid-rows-subgrid gap-0 border border-dusk-50 rounded hover:shadow-card transition-shadow p-6",
        { "!border-[#ECA674]": recommendation?.reason },
        className
      )}
    >
      <img
        src={program.image_url}
        alt={program.name}
        className="block w-full h-[120px] object-cover"
      />

      <h3 className="text-charcoal-900 text-lg font-body font-bold leading-[140%] tracking-0.36 mt-3">
        {program.name}
      </h3>

      <h4 className="text-dusk text-base font-body font-normal leading-[140%] tracking-0.32 mt-2">
        Potential earnings
      </h4>

      <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-2">
        {potentialEarningsText}
      </p>

      <h4 className="text-dusk text-base font-body font-normal leading-[140%] tracking-0.32 mt-3">
        Term length
      </h4>

      <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-2">
        {termText}
      </p>

      {prosConsEntryData ? (
        <>
          <h4 className="text-dusk text-base font-body font-normal leading-[140%] tracking-0.32 mt-3">
            Pros
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-2">
            {prosConsEntryData?.pros}
          </p>

          <h4 className="text-dusk text-base font-body font-normal leading-[140%] tracking-0.32 mt-3">
            Cons
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-0.32 mt-2">
            {prosConsEntryData?.cons}
          </p>
        </>
      ) : null}

      <ProjectComparisonModalRecommendation recommendation={recommendation} />
    </Link>
  )
}

export default ProjectComparisonModalCard
