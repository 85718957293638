import { useState } from "react"
import Select, {
  components,
  Props as SelectTypes,
  OptionProps as OptionTypes,
  MultiValueRemoveProps as MultiValueRemoveTypes,
  DropdownIndicatorProps as DropdownIndicatorTypes,
  ClearIndicatorProps as ClearIndicatorTypes,
} from "react-select"
import CreatableSelect from "react-select/creatable"
import { FormikValues, useField, useFormikContext } from "formik"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faChevronDown } from "@fortawesome/pro-duotone-svg-icons"

export interface OptionType {
  label: string
  value: string
}

interface InputSelectProps extends SelectTypes {
  name: string
  label?: string
  labelClass?: string
  options: string[]
  isValidNewOption?: (inputValue: string) => boolean
  className?: string
  isClearable?: boolean
}

interface MultiSelectProps extends SelectTypes {
  name: string
  label?: string
  labelClass?: string
  options: OptionType[]
  simpleValue?: boolean
}

const Option = ({ children, isSelected, ...props }: OptionTypes) => {
  return (
    <components.Option {...props} isSelected={isSelected}>
      <span className="flex mr-2 shrink-0 items-center relative">
        <div
          className={cx(
            "w-0 h-0 opacity-0 relative border-0 left-2",
            "[&.is-checked+span]:after:block [&.is-checked+span]:border-grass-900",
            {
              "is-checked": isSelected,
            }
          )}
        />

        <span
          className={cx(
            "relative block w-4 h-4 border border-charcoal-300 rounded-sm pointer-events-none bg-white",
            "after:content-[''] after:absolute after:hidden after:bg-checkmark after:top-[-1px] after:right-[-1px] after:w-4 after:h-4"
          )}
        />
      </span>
      <span>{children}</span>
    </components.Option>
  )
}

const MultiValueRemove = (props: MultiValueRemoveTypes) => {
  return (
    <components.MultiValueRemove {...props}>
      <span className="flex h-6 w-6 justify-center items-center">
        <FontAwesomeIcon icon={faXmark} />
      </span>
    </components.MultiValueRemove>
  )
}

const DropdownIndicator = (props: DropdownIndicatorTypes) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faChevronDown} />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: ClearIndicatorTypes) => {
  return (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={faXmark} />
    </components.ClearIndicator>
  )
}

const selectStyles = (isError: boolean, isClearable: boolean = false) => ({
  control: (baseStyles: any, { menuIsOpen }: any) => ({
    ...baseStyles,
    borderColor: menuIsOpen ? "#A4CAFE" : isError ? "#E02424" : "#E5E5E5",
    height: 40,
    overflow: "hidden",
    padding: "0 0 0 12px",
    boxShadow: menuIsOpen ? "0 0 0 3px rgba(164, 202, 254, 0.45)" : "none",
    ":hover": {
      borderColor: menuIsOpen ? "#A4CAFE" : isError ? "#E02424" : "#E5E5E5",
    },
  }),
  input: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "text",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#6B7280",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    height: 38,
    flexWrap: "no-wrap",
    padding: 0,
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    height: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 12px",
    color: "#6B7280",
    ":hover": { color: "#585F6D" },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  clearIndicator: () => ({
    display: isClearable ? "flex" : "none",
    height: "38px",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0 0 6px",
    color: "#6B7280",
    ":hover": {
      color: "#585F6D",
      cursor: "pointer",
    },
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    backgroundColor: "#EAEFEC",
    margin: "0 8px 0 0",
    padding: "0 4px",
  }),
  multiValueLabel: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: 16,
    lineHeight: "28px",
    padding: 0,
  }),
  multiValueRemove: (baseStyles: any) => ({
    ...baseStyles,
    ":hover": { backgroundColor: "#EAEFEC" },
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
    overflow: "hidden",
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    border: "none",
    padding: 0,
  }),
  menuPortal: (baseStyles: any) => ({
    ...baseStyles,
    zIndex: 2147483642,
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isFocused
      ? "#EAEFEC"
      : state.isActive
        ? "#EAEFEC"
        : "transparent",
    alignItems: "center",
    color: "inherit",
    display: "flex",
    padding: "7px 8px",
    lineHeight: "23px",
    ":hover": {
      backgroundColor: "#EAEFEC",
      cursor: "pointer",
    },
  }),
})

const createOption = (option: string) => ({
  label: option,
  value: option,
})

const defaultOptions = (options: string[]) =>
  options.map((option) => createOption(option))

export const InputSelect = ({
  name,
  label,
  labelClass,
  options,
  isValidNewOption = (inputValue) => inputValue.length > 0,
  className,
  isClearable,
  ...props
}: InputSelectProps) => {
  const [selectOptions, setSelectOptions] = useState(defaultOptions(options))
  const [field] = useField(name)
  const { setFieldValue, errors } = useFormikContext<FormikValues>()
  const isError = errors[name] !== undefined

  return (
    <>
      {label && (
        <label className={cx(labelClass ? labelClass : "inline")}>
          {label}
        </label>
      )}
      <CreatableSelect
        {...props}
        name={name}
        value={field.value}
        isValidNewOption={isValidNewOption}
        onCreateOption={(inputValue) => {
          const newOption = createOption(inputValue)
          setSelectOptions((prev) => [...prev, newOption])
          setFieldValue(name, newOption)
        }}
        onChange={(newValue) => {
          setFieldValue(name, newValue)
        }}
        options={selectOptions}
        components={{
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: ClearIndicator,
        }}
        styles={selectStyles(isError, isClearable)}
        className={cx("[&_input[type='text']:focus:shadow-none", className)}
        isClearable={isClearable}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      {errors[name] !== undefined ? (
        <div className="text-sm text-red-600 mt-1">
          {errors[name] as string}
        </div>
      ) : null}
    </>
  )
}

const MultiSelect = ({
  name,
  label,
  labelClass,
  simpleValue = false,
  options,
  ...props
}: MultiSelectProps) => {
  const [field] = useField(name)
  const { setFieldValue, errors } = useFormikContext<FormikValues>()
  const isError = errors[name] !== undefined
  const flattenedOptions = options?.flatMap((o: OptionType) => o)
  const value = flattenedOptions?.filter((o: OptionType) =>
    field.value.includes(o.value)
  )

  return (
    <>
      {label && (
        <label className={cx(labelClass ? labelClass : "inline")}>
          {label}
        </label>
      )}
      <Select
        {...props}
        maxMenuHeight={130}
        value={value}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isSearchable={false}
        isMulti
        onChange={(newValue) => {
          const selectedOptions = newValue as OptionType[]
          const fieldValue = simpleValue
            ? selectedOptions.reduce((acc: string, o: OptionType) => {
                return `${acc}${!acc.length ? "" : ", "}${o.value}`
              }, "")
            : selectedOptions.map((o: OptionType) => o.value)

          setFieldValue(name, fieldValue)
        }}
        options={options}
        components={{
          Option: Option,
          MultiValueRemove: MultiValueRemove,
          DropdownIndicator: DropdownIndicator,
        }}
        styles={selectStyles(isError)}
      />
      {errors[name] !== undefined ? (
        <div className="text-sm text-red-600 mt-1">
          {errors[name] as string}
        </div>
      ) : null}
    </>
  )
}

export default MultiSelect
