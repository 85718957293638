import { ReactNode } from "react"
import cx from "classnames"

export interface ScoreCardHeadingProps {
  children: ReactNode
  className?: string
}

const ScoreCardHeading = ({
  children,
  className = "",
}: ScoreCardHeadingProps) => (
  <h4
    className={cx(
      "text-charcoal-500 text-left font-body font-bold leading-[24px] tracking-0.32",
      className
    )}
  >
    {children}
  </h4>
)

export default ScoreCardHeading
