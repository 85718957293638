import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"

interface FiltersOpenProps {
  onClick: () => void
  activeFiltersNum: number
}

const FiltersOpen = ({ onClick, activeFiltersNum }: FiltersOpenProps) => {
  return (
    <button
      type="button"
      className="relative btn2 border border-dusk-100 rounded text-base text-charcoal-500 font-semibold p-[0.4375rem]"
      onClick={onClick}
      aria-label="Open filters"
    >
      Filters
      <span className="inline-block ml-1">
        <FontAwesomeIcon
          icon={faFilter}
          className="w-6 [&>path]:fill-white [&>path]:stroke-charcoal-500 [&>path]:stroke-[8%]"
        />
      </span>
      {activeFiltersNum > 0 && (
        <span className="flex justify-center items-center absolute top-0 right-3 w-6 h-6 rounded-full bg-charcoal-900 text-cloud-50 text-sm font-bold leading-130 -r-[17px] xs:-right-[14px]">
          {activeFiltersNum}
        </span>
      )}
    </button>
  )
}

export default FiltersOpen
