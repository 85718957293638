import { useState, CSSProperties } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import debounce from "lodash/debounce"
import cx from "classnames"
import { formInputClass } from "../shared/constants"

export interface SearchFieldProps {
  id?: string
  initialValue?: string
  onChange: (value: string) => void
  className?: string
  placeholder?: string
  show?: boolean
}

const SearchField = ({
  id = "search-field",
  initialValue = "",
  onChange,
  className = "",
  placeholder = "Search",
  show = true,
}: SearchFieldProps) => {
  const [value, setValue] = useState(initialValue)
  const debounceOnChange = debounce(onChange, 250)

  if (!show) {
    return null
  }

  return (
    <div
      className={cx("relative rounded inline-block w-full", className)}
      data-testid="search-field"
    >
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-dusk">
        <FontAwesomeIcon icon={faSearch} />
      </div>

      <input
        id={id}
        className={cx(
          formInputClass,
          "block w-full disabled:bg-gray-100 read-only:bg-gray-100",
          "px-9 border-cloud"
        )}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          debounceOnChange(e.target.value)
        }}
        placeholder={placeholder}
        type="text"
      />

      {value ? (
        <button
          className="absolute top-2 bottom-2 right-3 w-6 text-center leading-6.5 cursor-pointer"
          onClick={() => {
            setValue("")
            onChange("")
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={
              {
                "--fa-primary-opacity": 0,
                "--fa-secondary-opacity": 1,
              } as CSSProperties
            }
            title={faXmark.iconName}
          />
        </button>
      ) : null}
    </div>
  )
}

export default SearchField
