import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

import useMediaQuery from "../../hooks/useMediaQuery"

export interface NumericPaginationPageLinkTypes {
  children: ReactNode
  linkType: "dots" | "current" | "disabled" | "default"
  page: number | string
  className: string
  urlRoot: string
  ariaLabel?: string
  queryParams?: boolean
}

const NumericPaginationPageLink = ({
  children,
  linkType,
  page,
  className,
  urlRoot,
  ariaLabel,
  queryParams,
}: NumericPaginationPageLinkTypes) => {
  const isMaxWidth390px = useMediaQuery("(max-width: 390px)")

  const pageLinkVariants = {
    dots: (
      <span
        className={cx(
          "np-dots flex justify-center items-center w-10 sm:w-11 h-10 sm:h-11 rounded border border-solid border-dusk-100 text-charcoal-500 font-semibold text-base",
          { "mb-2": isMaxWidth390px }
        )}
        aria-label="Dots"
      >
        {children}
      </span>
    ),
    disabled: (
      <span
        className={cx(
          "np-disabled flex justify-center items-center w-10 sm:w-11 h-10 sm:h-11 rounded border border-solid border-charcoal-50 bg-charcoal-50 text-dusk-500 opacity-50 font-semibold text-base",
          { "mb-2": isMaxWidth390px }
        )}
        aria-label="Disabled"
      >
        {children}
      </span>
    ),
    current: (
      <span
        className={cx(
          "np-current flex justify-center items-center w-10 sm:w-11 h-10 sm:h-11 rounded border border-solid border-grass text-grass font-semibold text-base",
          { "mb-2": isMaxWidth390px }
        )}
        aria-label="Current"
      >
        {children}
      </span>
    ),
    default: (
      <Link
        to={`${urlRoot}${queryParams ? "?page=" : "/"}${page}`}
        className={cx(
          "np-link",
          "flex justify-center items-center w-10 sm:w-11 h-10 sm:h-11 rounded border border-solid border-dusk-100 font-semibold text-base transition-all duration-200 ease-in-out transform",
          "hover:border-grass-900 active:scale-90",
          { "mb-2": isMaxWidth390px },
          className
        )}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        aria-label={ariaLabel ? ariaLabel : `Go to page ${children}`}
      >
        {children}
      </Link>
    ),
  }

  return pageLinkVariants[linkType] || pageLinkVariants.default
}

export default NumericPaginationPageLink
