import { ReactNode } from "react"

interface PropertyMapContainerProps {
  children: ReactNode
}

const PropertyMapContainer = ({ children }: PropertyMapContainerProps) => (
  <div className="relative bg-white rounded-md my-4 p-0">
    <div className="lg:h-[400px] relative lg:flex rounded overflow-hidden">
      {children}
    </div>
  </div>
)

export default PropertyMapContainer
