import { MouseEvent, MouseEventHandler, ReactNode } from "react"
import { publicApiType, ScrollMenu } from "react-horizontal-scrolling-menu"
import { DialogStateReturn } from "reakit"
import cx from "classnames"

import ProjectCard from "../ProjectCard"
import SimilarProgramsSliderArrowLeft from "./SimilarProgramsSliderArrowLeft"
import SimilarProgramsSliderArrowRight from "./SimilarProgramsSliderArrowRight"
import useDrag from "../../hooks/useDrag"
import usePreventBodyScroll from "../../hooks/usePreventBodyScroll"
import useMediaQuery from "../../hooks/useMediaQuery"
import { onWheel } from "../../shared/utils"
import { ProgramType } from "../../types/program"

export interface SimilarProgramsSliderTypes {
  programs: ProgramType[]
  className?: string
  cardClassName?: string
  header: ReactNode
  showMinimalInfo?: boolean
  eligibilityDialog?: DialogStateReturn | null
}

const SimilarProgramsSlider = ({
  programs = [],
  className = "",
  cardClassName = "",
  header,
  showMinimalInfo = false,
  eligibilityDialog = null,
}: SimilarProgramsSliderTypes) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const { disableScroll, enableScroll } = usePreventBodyScroll()
  const isMaxWidth1280px = useMediaQuery("(max-width: 1280px)")

  const handleDrag: (api: publicApiType) => MouseEventHandler<Element> =
    ({ scrollContainer }) =>
    (ev: MouseEvent) => {
      dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })
    }

  return (
    <>
      <div
        className={cx(
          "similar-programs-slider w-[calc(100%+40px)] sm:w-0 sm:grow relative -mx-5 sm:mx-0 mb-6 sm:mb-24",
          className
        )}
        onMouseEnter={disableScroll}
        onMouseLeave={() => {
          enableScroll()
          dragStop()
        }}
      >
        {header}

        <ScrollMenu
          LeftArrow={SimilarProgramsSliderArrowLeft}
          RightArrow={SimilarProgramsSliderArrowRight}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          onWheel={onWheel}
        >
          {programs.map((program) => (
            <div
              className={cx(
                "flex w-208 md:w-348 lg:w-312 xl:w-344 [&>.project-card]:max-w-full",
                {
                  "[&>.project-card-container_a>div]:last:grid-cols-1":
                    isMaxWidth1280px,
                }
              )}
              key={program.id}
            >
              <ProjectCard
                className={cx(cardClassName, {
                  "pointer-events-none": dragging,
                })}
                programData={program}
                showMinimalInfo={showMinimalInfo}
                onClick={eligibilityDialog?.hide}
              />
            </div>
          ))}
        </ScrollMenu>
      </div>
    </>
  )
}

export default SimilarProgramsSlider
