import FilterSwitch from "./FilterSwitch"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectFiltersDrawerFilterSwitchesProps {
  handleFirstPageRedirect: () => void
}

const ProjectFiltersDrawerFilterSwitches = ({
  handleFirstPageRedirect,
}: ProjectFiltersDrawerFilterSwitchesProps) => {
  const {
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    setGovtProjectsOn,
    setIneligibleProjectsOn,
    setHasLandownerCost,
  } = useProjectsStore()

  const handleFilterChange = (
    setFilter: (value: boolean) => void,
    currentValue: boolean
  ) => {
    setFilter(!currentValue)
    handleFirstPageRedirect()
  }

  return (
    <div className="flex flex-col gap-4">
      <FilterSwitch
        label="Ineligible programs"
        value={ineligibleProjectsOn}
        onChange={() =>
          handleFilterChange(setIneligibleProjectsOn, ineligibleProjectsOn)
        }
      />

      <FilterSwitch
        label="Government programs"
        value={govtProjectsOn}
        onChange={() => handleFilterChange(setGovtProjectsOn, govtProjectsOn)}
      />

      <FilterSwitch
        label="Has landowner cost"
        value={hasLandownerCost}
        onChange={() =>
          handleFilterChange(setHasLandownerCost, hasLandownerCost)
        }
      />
    </div>
  )
}

export default ProjectFiltersDrawerFilterSwitches
