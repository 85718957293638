import { ReactNode } from "react"
import { Dialog, DialogStateReturn } from "reakit"
import cx from "classnames"

import ModalBackdrop from "../../../components/ModalBackdrop"
import { CloseButton } from "../../../components/CloseButton"

export interface ScoreCardDialogProps {
  dialog: DialogStateReturn
  heading: string
  children: ReactNode
}

const ScoreCardDialog = ({
  dialog,
  heading,
  children,
}: ScoreCardDialogProps) => (
  <ModalBackdrop dialog={dialog}>
    <Dialog
      {...dialog}
      className={cx(
        "st-dialog scorecard-dialog w-full max-w-[816px] max-h-full px:2 sm:px-12 py-6 "
      )}
      hideOnEsc={true}
      hideOnClickOutside={true}
      aria-label="Scorecard dialog"
    >
      <div>
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-dusk text-sm uppercase font-body font-bold leading-120 tracking-[0.84px]">
            scorecard • {heading}
          </h2>

          <CloseButton
            className="shrink-0 ml-4 rounded focus:outline-none text-charcoal-500 font-bold"
            aria-label="Close modal"
            onClick={dialog.hide}
          />
        </div>

        <div>{children}</div>
      </div>
    </Dialog>
  </ModalBackdrop>
)

export default ScoreCardDialog
