import { ReactNode } from "react"
import assert from "assert"
import cx from "classnames"
import { useField } from "formik"
// DEV: We're aware Reakit combobox is unstable but we're avoiding experimental features - https://reakit.io/docs/combobox/
import {
  unstable_useComboboxState as useComboboxState,
  unstable_Combobox as ReakitCombobox,
  unstable_ComboboxPopover as ComboboxPopover,
  unstable_ComboboxOption as ComboboxOption,
} from "reakit/Combobox"

import { formInputClass } from "../shared/constants"

export interface Option {
  value: string
  label: string
  popoverContent: ReactNode | ((props: { selected: boolean }) => ReactNode)
}

export interface ComboboxProps {
  className?: string
  disabled?: boolean
  label: string
  options: Option[]
  popoverClassName?: string
  "aria-label"?: string
  name: string
}

export const Combobox = ({
  className,
  disabled = false,
  label,
  options,
  popoverClassName,
  "aria-label": ariaLabel,
  name,
}: ComboboxProps) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")

  const [field, meta, helpers] = useField(name)
  const combobox = useComboboxState({
    loop: true,
    gutter: 4,
  })

  return (
    <div className={cx("relative", className)}>
      <div className="relative">
        <ReakitCombobox
          {...field}
          {...combobox}
          as="button"
          aria-label={ariaLabel || label}
          className={cx(
            formInputClass,
            "block w-full disabled:bg-gray-100 read-only:bg-gray-100",
            "pr-10 cursor-default text-left truncate",
            { "bg-gray-100": disabled },
            {
              "border-red-500 focus:border-red-300 focus:ring focus:ring-red-300/45 [&::placeholder]:text-red-600 [&_.placeholder]:text-red-600":
                meta.touched && meta.error,
            }
          )}
          disabled={disabled}
          type="button"
        >
          {field.value ? (
            options?.find((option) => option.value === field.value)?.label
          ) : (
            <span className="placeholder text-gray2">Choose</span>
          )}
        </ReakitCombobox>

        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <i className="fas fa-chevron-down text-sm text-gray2" />
        </span>
      </div>

      <ComboboxPopover
        {...combobox}
        aria-label={ariaLabel || label}
        as="ul"
        className={cx(
          "absolute z-combobox w-full bg-white shadow-lg max-h-80 rounded-md py-1 text-base overflow-auto focus:outline-none",
          popoverClassName
        )}
      >
        {options.map((option) => {
          const isSelected = option.value === combobox.inputValue

          return (
            <ComboboxOption
              {...combobox}
              as="li"
              className={cx(
                "text-carbon cursor-pointer select-none relative py-5 pl-6 pr-4 hover:bg-green-light hover:bg-opacity-50",
                {
                  "bg-green-light bg-opacity-50": isSelected,
                }
              )}
              key={option.value}
              value={option.value}
              onClick={() => {
                helpers.setValue(option.value)
              }}
            >
              {typeof option.popoverContent === "function"
                ? option.popoverContent({
                    selected: isSelected,
                  })
                : option.popoverContent}
            </ComboboxOption>
          )
        })}
      </ComboboxPopover>
    </div>
  )
}
