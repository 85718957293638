import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { getCompare } from "../../../api/data"
import { useMutationWithRefresh } from "../utils"
import { ProsConsDataType } from "@/types/comparison"

export function useGetCompare<
  TData extends ProsConsDataType,
  TError,
  TVariables extends number[],
>(
  queryClient: QueryClient,
  accountId: string,
  config = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => getCompare(accountId, values),
    config
  )
}
