interface HeadingTypes {
  heading: string
}

const Heading = ({ heading }: HeadingTypes) => {
  if (!heading?.length) {
    return null
  }

  return (
    <h5 className="font-body font-normal text-base text-charcoal-500 [&+h5]:mt-4 [&+p]:mt-1">
      {heading}
    </h5>
  )
}

export default Heading
