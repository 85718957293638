import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface ProjectComparisonModalSkeletonLoaderCardProps {
  showText?: boolean
}

interface ProjectComparisonModalSkeletonLoaderProps {
  comparisonLength: number
  isMaxWidthMd: boolean
}

const ProjectComparisonModalSkeletonLoaderCard = ({
  showText = false,
}: ProjectComparisonModalSkeletonLoaderCardProps) => (
  <div className="border border-dusk-50 rounded p-6">
    <div className="flex justify-center items-center bg-dusk-200 mb-3 w-full h-[121px] p-4">
      {showText ? (
        <p className="text-gray-700 text-overline text-center">
          Personalizing our analysis based on your goals…
        </p>
      ) : null}
    </div>

    <div className="bg-dusk-200 mb-3 w-full h-[25px] mt-3" />

    <div className="bg-dusk-200 mb-3 w-1/3 h-[22px] mt-2" />
    <div className="bg-dusk-200 mb-3 w-full h-[22px] mt-2" />

    <div className="bg-dusk-200 mb-3 w-1/4 h-[22px] mt-3" />
    <div className="bg-dusk-200 mb-3 w-full h-[22px] mt-2" />

    <div className="bg-dusk-200 mb-3 w-1/5 h-[22px] mt-3" />
    <div className="bg-dusk-200 mb-3 w-full h-[66px] mt-2" />

    <div className="bg-dusk-200 mb-3 w-1/5 h-[22px] mt-3" />
    <div className="bg-dusk-200 mb-3 w-full h-[66px] mt-2" />
  </div>
)

const ProjectComparisonModalSkeletonLoader = ({
  comparisonLength,
  isMaxWidthMd,
}: ProjectComparisonModalSkeletonLoaderProps) => {
  const cards = Array.from(Array(comparisonLength).keys())

  if (isMaxWidthMd) {
    return (
      <div className="animate-pulse">
        <div className="block absolute top-14 left-2 right-2 bottom-[114px] overflow-hidden py-4">
          <ProjectComparisonModalSkeletonLoaderCard showText />
        </div>

        <div className="fixed bottom-[34px] left-0 right-0 flex justify-end mt-4 border-t-1 border-dusk-50 px-4 pb-3 pt-7">
          <div className="bg-dusk-200 h-10 w-[175px] rounded" />
        </div>

        <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center gap-2 pb-[10px]">
          <span className="h-6 w-6 flex items-center justify-center text-dusk-200">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>

          {cards.map((tick) => (
            <div key={tick} className="w-3 h-3 bg-dusk-200 rounded-full" />
          ))}

          <span className="h-6 w-6 flex items-center justify-center text-dusk-200">
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="animate-pulse">
      <div
        className={cx(
          "grid gap-4 lg:gap-8",
          comparisonLength === 3 ? "grid-cols-3" : "grid-cols-2"
        )}
      >
        {cards.map((card: number) => (
          <ProjectComparisonModalSkeletonLoaderCard key={card} />
        ))}
      </div>

      <div className="flex justify-between items-center mt-4">
        <p className="text-gray-700 text-overline text-center">
          Personalizing our analysis based on your goals…
        </p>

        <div className="bg-dusk-200 h-10 w-[175px] rounded" />
      </div>
    </div>
  )
}

export default ProjectComparisonModalSkeletonLoader
