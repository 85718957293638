import { useState } from "react"
import { useDialogState } from "reakit"

import ContentCard from "../../../components/ContentCard"
import HelperCard from "../../../components/HelperCard"
import ScoreCardHeader from "./ScoreCardHeader"
import ScoreCardItems from "./ScoreCardItems"
import ScoreCardDialog from "./ScoreCardDialog"
import ScoreCardDialogContent from "./ScoreCardDialogContent"
import { SCORE_CARDS } from "./constants"
import { getCategoryFromType, getTopItemsByPotentialEarnings } from "./helpers"
import { ScoreData } from "@/types/property"
import { ProjectDataTypes } from "@/types/program"

interface ScorecardProps {
  scoreData: ScoreData | null
  programsData: ProjectDataTypes[]
  forestedAcres: number | undefined
  isMember: boolean | undefined
}

const ScoreCard = ({
  scoreData,
  programsData,
  forestedAcres,
  isMember,
}: ScorecardProps) => {
  const scoreCardDialog = useDialogState({ animated: true })
  const [activeScoreCardItem, setActiveScoreCardItem] = useState<string | null>(
    null
  )
  const [potentialIncomePrograms, setPotentialIncomePrograms] = useState<
    ProjectDataTypes[] | null
  >(null)

  const handleActiveScoreCardItem = (item: string) => {
    const itemType = getCategoryFromType(item)

    const topItemsByPotentialEarnings = getTopItemsByPotentialEarnings(
      programsData,
      itemType,
      2
    )

    setActiveScoreCardItem(item)
    setPotentialIncomePrograms(topItemsByPotentialEarnings)
  }

  if (!scoreData || !forestedAcres || forestedAcres <= 5) {
    // if we have forest acreage and its below minimum we trust, show specific message.
    // if we don't have forest acreage, we are still calculating landcover info, so same show generic message as no score data
    const subheader =
      forestedAcres && forestedAcres <= 5
        ? "Natural Capital Scorecard is currently only available for landowners with 5+ forested acres. We will notify you when we expand our capabilities to new land cover types and acreage amounts."
        : "Your Natural Capital Scorecard is still being processed. Check back soon!"
    return (
      <ContentCard className="shadow" roundedMd>
        <ScoreCardHeader subheader={subheader} />
      </ContentCard>
    )
  }

  const dialogTitle = activeScoreCardItem
    ? SCORE_CARDS[activeScoreCardItem].heading
    : ""

  return (
    <ContentCard className="shadow" roundedMd>
      <ScoreCardHeader
        subheader={
          "View the scorecard for your Natural Capital Portfolio. Select a natural capital asset to learn more."
        }
      />

      <ScoreCardItems
        dialog={scoreCardDialog}
        scoreData={scoreData}
        handleActiveScoreCardItem={handleActiveScoreCardItem}
      />

      <HelperCard className="mt-4">
        A score is a quantification of an asset in a landowner's Natural Capital
        Portfolio. Scores are based out of 10. Your property's potential may be
        lower than the nationwide maximum score.{" "}
        <a
          href="https://ncx.com/learning-hub/natural-capital-scorecard/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Learn more"
        >
          Learn more
        </a>
      </HelperCard>

      <ScoreCardDialog dialog={scoreCardDialog} heading={dialogTitle}>
        <ScoreCardDialogContent
          activeScoreCardItem={activeScoreCardItem}
          scoreData={scoreData}
          potentialIncomePrograms={potentialIncomePrograms}
          dialog={scoreCardDialog}
          isMember={isMember}
        />
      </ScoreCardDialog>
    </ContentCard>
  )
}

export default ScoreCard
