import cx from "classnames"

import ProjectComparisonModalSkeletonLoader from "./ProjectComparisonModalSkeletonLoader"
import ProjectComparisonModalCard from "./ProjectComparisonModalCard"
import ProjectComparisonModalSlider from "./ProjectComparisonModalSlider"
import ProjectComparisonModalFooter from "./ProjectComparisonModalFooter"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { ProsConsDataType } from "@/types/comparison"

interface ProjectComparisonModalContentProps {
  isFetching: boolean
  compareData: ProsConsDataType | undefined
  PDFDownloadLink: string | null
}

const ProjectComparisonModalContent = ({
  isFetching,
  compareData,
  PDFDownloadLink,
}: ProjectComparisonModalContentProps) => {
  const { comparison } = useProjectsStore()
  const isMaxWidthMd = useMediaQuery("(max-width: 767px)")

  if (isFetching && compareData === undefined) {
    return (
      <ProjectComparisonModalSkeletonLoader
        comparisonLength={comparison.length}
        isMaxWidthMd={isMaxWidthMd}
      />
    )
  }

  if (isMaxWidthMd) {
    return (
      <ProjectComparisonModalSlider
        compareData={compareData}
        PDFDownloadLink={PDFDownloadLink}
      />
    )
  }

  return (
    <>
      <div
        className={cx(
          "w-full hidden md:grid gap-4 lg:gap-8",
          comparison.length === 3 ? "grid-cols-3" : "grid-cols-2"
        )}
      >
        {comparison.map((program) => (
          <ProjectComparisonModalCard
            key={program.id}
            program={program}
            prosConsEntryData={
              compareData && compareData?.pros_cons[program.id]
            }
            recommendation={
              program.id.toString() ===
              compareData?.recommendation.recommended_project_id
                ? compareData?.recommendation
                : null
            }
            className={
              !isNaN(Number(compareData?.recommendation.recommended_project_id))
                ? "row-span-11"
                : "row-span-10"
            }
          />
        ))}
      </div>

      <ProjectComparisonModalFooter
        PDFDownloadLink={PDFDownloadLink}
        recommendation={compareData?.recommendation}
      />
    </>
  )
}

export default ProjectComparisonModalContent
