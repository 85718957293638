import { CloseButton } from "../../../components/CloseButton"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectComparisonCardProps {
  id: number
  image: string
  name: string
}

const ProjectComparisonCard = ({
  id,
  image,
  name,
}: ProjectComparisonCardProps) => {
  const { removeFromComparison } = useProjectsStore()

  const handleRemove = () => {
    removeFromComparison(id)
  }

  return (
    <div className="flex items-center border border-dusk-200 rounded overflow-hidden">
      <img
        src={image}
        alt={name}
        className="h-[100px] w-[120px] shrink-0 object-cover"
      />

      <h3 className="text-charcoal-500 text-sm xl:text-lg font-body font-medium line-clamp-3 leading-[140%] tracking-[0.28px] xl:tracking-0.36 ml-4">
        {name}
      </h3>

      <div className="h-10 w-[17px] xl:w-[51px] flex justify-center shrink-0 ml-auto">
        <div className="w-px h-10 bg-dusk-100" />
      </div>

      <CloseButton
        className="shrink-0 mr-6 focus:outline-none text-charcoal-500 font-bold"
        aria-label="Remove program"
        onClick={handleRemove}
      />
    </div>
  )
}

export default ProjectComparisonCard
