import ToggleSwitch from "../../components/ToggleSwitch"
import NothingFound from "../../components/NothingFound"
import ProjectCard from "../../components/ProjectCard"
import { useFirstPageRedirect } from "../../hooks/useFirstPageRedirect"
import { useProjectsStore } from "../../stores/useProjectsStore"
import { ProjectDataTypes } from "../../types/program"

interface ProjectCardsProps {
  pageSize: number
  pageId: string | undefined
  projectsData: ProjectDataTypes[]
  projectsDataIneligibleOnLength: number
  isMember: boolean | undefined
}

const ProjectCards = ({
  pageSize,
  pageId,
  projectsData,
  projectsDataIneligibleOnLength,
  isMember,
}: ProjectCardsProps) => {
  const { ineligibleProjectsOn, setIneligibleProjectsOn, comparisonOn } =
    useProjectsStore()
  const handleFirstPageRedirect = useFirstPageRedirect()

  if (projectsData.length === 0) {
    return (
      <NothingFound
        title={
          projectsDataIneligibleOnLength === 0
            ? "No programs found"
            : "No eligible programs"
        }
        text={
          projectsDataIneligibleOnLength === 0
            ? "There are no project opportunities with those filters. Clear a filter to try again."
            : "You're not eligible for any programs in this category. To see more, turn on ineligible programs."
        }
        linkText="View Dashboard"
        className="col-span-full py-18.5"
        component={
          projectsDataIneligibleOnLength > 0 ? (
            <div className="flex p-1.75 border border-dusk-50 rounded order-3">
              <ToggleSwitch
                label="Ineligible programs"
                value={ineligibleProjectsOn}
                onChange={() => {
                  setIneligibleProjectsOn(!ineligibleProjectsOn)
                  handleFirstPageRedirect()
                }}
              />
            </div>
          ) : null
        }
        small
      />
    )
  }

  const projectsDataPage = projectsData.slice(
    (Number(pageId) - 1) * pageSize,
    (Number(pageId) - 1) * pageSize + pageSize
  )

  return projectsDataPage.map((program) => (
    <ProjectCard
      key={program.id}
      programData={program}
      compare={comparisonOn}
      isMember={isMember}
    />
  ))
}

export default ProjectCards
