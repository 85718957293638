import { useEffect, useRef, useState } from "react"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import ProjectComparisonModalCard from "./ProjectComparisonModalCard"
import ProjectComparisonModalSliderArrow from "./ProjectComparisonModalSliderArrow"
import ProjectComparisonModalFooter from "./ProjectComparisonModalFooter"
import ProjectComparisonModalNoRecommended from "./ProjectComparisonModalNoRecommended"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { ProsConsDataType } from "@/types/comparison"

interface ProjectComparisonModalSliderProps {
  compareData: ProsConsDataType | undefined
  PDFDownloadLink: string | null
}

const ProjectComparisonModalSlider = ({
  compareData,
  PDFDownloadLink,
}: ProjectComparisonModalSliderProps) => {
  const { comparison } = useProjectsStore()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const startX = useRef<number | null>(null)
  const offset = useRef<number>(0)

  const programsLength = comparison.length

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && contentRef.current) {
        setIsOverflowing(
          contentRef.current.scrollHeight > containerRef.current.clientHeight
        )
      }
    }

    checkOverflow()
    window.addEventListener("resize", checkOverflow)
    return () => window.removeEventListener("resize", checkOverflow)
  }, [comparison, currentIndex])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % programsLength)
  }

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + programsLength) % programsLength
    )
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startX.current !== null) {
      offset.current = e.touches[0].clientX - startX.current
    }
  }

  const handleTouchEnd = () => {
    if (offset.current > 50 && currentIndex > 0) {
      handlePrev()
    } else if (offset.current < -50 && currentIndex < programsLength - 1) {
      handleNext()
    }
    startX.current = null
    offset.current = 0
  }

  const showNoRecommended = false

  return (
    <>
      <div
        ref={containerRef}
        className="block absolute top-14 left-2 right-2 bottom-[115px] overflow-x-hidden py-4 z-[1]"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          ref={contentRef}
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {comparison.map((program) => (
            <div key={program.id} className="flex-shrink-0 w-full">
              <ProjectComparisonModalCard
                program={program}
                prosConsEntryData={
                  compareData && compareData?.pros_cons[program.id]
                }
                recommendation={
                  program.id.toString() ===
                  compareData?.recommendation.recommended_project_id
                    ? compareData?.recommendation
                    : null
                }
                className={
                  !isNaN(
                    Number(compareData?.recommendation.recommended_project_id)
                  )
                    ? "row-span-11"
                    : "row-span-10"
                }
              />
            </div>
          ))}
        </div>

        <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center gap-2 pb-[10px]">
          <ProjectComparisonModalSliderArrow
            onClick={handlePrev}
            disabled={currentIndex === 0}
            icon={faArrowLeft}
          />

          {comparison.map((_, index: number) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={cx("w-3 h-3 bg-dusk-300 rounded-full", {
                "bg-leaf": index === currentIndex,
              })}
              disabled={index === currentIndex}
            />
          ))}

          <ProjectComparisonModalSliderArrow
            onClick={handleNext}
            disabled={currentIndex === programsLength - 1}
            icon={faArrowRight}
          />
        </div>

        <ProjectComparisonModalNoRecommended
          show={showNoRecommended}
          className="inline-flex items-center mt-3"
          reason={compareData?.recommendation?.reason}
        />
      </div>

      <ProjectComparisonModalFooter
        PDFDownloadLink={PDFDownloadLink}
        recommendation={compareData?.recommendation}
        className={cx("fixed bottom-0 left-0 right-0 px-4 pb-[46px] pt-7", {
          "shadow-[0px_-2px_8px_2px_rgba(0,0,0,0.18)]": isOverflowing,
        })}
      />
    </>
  )
}

export default ProjectComparisonModalSlider
