import {
  Tooltip,
  TooltipArrow,
  TooltipReference,
  useTooltipState,
} from "reakit/Tooltip"
import { Button } from "reakit/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { shortenAcreage } from "../../utils"
import useMediaQuery from "../../hooks/useMediaQuery"

interface TeaserMapFooterTypes {
  showAcreageSpinner: boolean
  acreage: number
  onSubmit: () => void
}

const TeaserMapFooter = ({
  showAcreageSpinner = false,
  acreage,
  onSubmit,
}: TeaserMapFooterTypes) => {
  const tooltip = useTooltipState()
  const isMaxWidthXl = useMediaQuery("(max-width: 1279px)")

  return (
    <div
      className={cx(
        "w-full h-[84px] flex items-center gap-x-12 col-span-2 px-5 py-4",
        { "fixed bottom-0 right-0 left-0 z-10 bg-white": isMaxWidthXl }
      )}
    >
      <button
        type="button"
        className="btn2 btn2-primary font-semibold"
        onClick={() => onSubmit()} // Attach the Formik onSubmit function
      >
        Claim Property
      </button>

      <div className="inline-block ml-auto xl:ml-0">
        <div className="font-light text-2xl leading-6">
          {showAcreageSpinner ? (
            <i className="fas fa-sync-alt fa-spin text-smoke"></i>
          ) : (
            shortenAcreage(acreage)
          )}
        </div>

        <div>total acres</div>
      </div>

      <TooltipReference
        {...tooltip}
        as={Button}
        className="text-base items-center gap-1 ml-auto mr-6 hidden xl:flex"
      >
        <FontAwesomeIcon icon={faCircleQuestion} />
        <strong>Help</strong>
      </TooltipReference>

      <Tooltip {...tooltip} className="st-tooltip">
        <div className="text-sm max-w-sm p-3 st-tooltip-inner">
          <TooltipArrow {...tooltip} />
          Total acres are calculated from your property boundary selection. It
          is normal for this to differ slightly from legal records.
        </div>
      </Tooltip>
    </div>
  )
}

export default TeaserMapFooter
