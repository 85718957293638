import BookAnExpertConsultation from "./BookAnExpertConsultation"
import MembershipBanner from "./MembershipBanner"
import Scorecard from "./Scorecard"
import LandProfile from "./LandProfile"
import NotificationContent from "./NotificationContent"
import Land from "./Land/Land"
import DashboardAccountInfo from "./DashboardAccountInfo"
import { useAccountId } from "../../hooks"
import { AccountTypes } from "@/types/account"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { NotificationTypes } from "@/types/notifications"
import { AccountInterestTypes } from "@/types/accountInterest"
import { AccountProperty } from "@/types/property"
import { EligibilityQuizzes } from "@/types/eligibility"
import { Profile } from "@/types"
import { ProjectDataTypes } from "@/types/program"

interface ViewAccountContentProps {
  account: AccountTypes | undefined
  accounts: DashboardAccountsTypes | undefined
  notifications: NotificationTypes[] | undefined
  accountInterestData: AccountInterestTypes | undefined
  property: AccountProperty | undefined
  quizzesData: EligibilityQuizzes | undefined
  profile: Profile | undefined
  isCheckoutSuccess: boolean
  programsData: ProjectDataTypes[]
}

const ViewAccountContent = ({
  account,
  accounts,
  notifications,
  accountInterestData,
  property,
  quizzesData,
  profile,
  isCheckoutSuccess,
  programsData,
}: ViewAccountContentProps) => {
  const accountId = useAccountId()

  const accountsCount: number | undefined = accounts && accounts.count

  const dataLoaded = !!(
    account &&
    accounts &&
    property &&
    notifications &&
    profile &&
    quizzesData &&
    accountInterestData
  )

  if (!dataLoaded) {
    return null
  }

  const scoreData = property?.score_data

  // if score data then round the scores
  if (scoreData) {
    // iterate over the score data and round the scores
    Object.keys(scoreData).forEach((key) => {
      if (scoreData[key].current_score !== undefined) {
        scoreData[key].current_score = Math.round(scoreData[key].current_score)
      }
      if (scoreData[key].max_score !== undefined) {
        scoreData[key].max_score = Math.round(scoreData[key].max_score)
      }
    })
  }

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
      <MembershipBanner isMember={account?.is_member} />

      <div className="xl:col-span-2 space-y-4">
        <NotificationContent
          accountId={accountId}
          accountsCount={accountsCount}
          notifications={notifications}
        />

        <BookAnExpertConsultation
          show={
            isCheckoutSuccess === false &&
            !!account &&
            account?.is_member &&
            account?.has_purchased_nca_report === false
          }
          accountRole={account?.role}
          isMember={account?.is_member}
        />

        <Scorecard
          scoreData={scoreData}
          programsData={programsData}
          forestedAcres={property.landcover_data?.forest.acres}
          isMember={account?.is_member}
        />

        <LandProfile quizzesData={quizzesData} />

        <Land accountId={accountId} profile={profile} property={property} />
      </div>

      <div>
        <DashboardAccountInfo
          acreage={property.acreage}
          accountInterestData={accountInterestData}
        />
      </div>
    </div>
  )
}

export default ViewAccountContent
