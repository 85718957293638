import cx from "classnames"

export interface ScoreCardHeadingPillProps {
  scoreColorClass: string
  scoreRating: string
}

const ScoreCardHeadingPill = ({
  scoreColorClass,
  scoreRating,
}: ScoreCardHeadingPillProps) => (
  <span
    className={cx(
      "inline-block h-6 text-white text-sm font-normal rounded leading-[24px] tracking-[0.28px] px-4 ml-2",
      scoreColorClass
    )}
  >
    {scoreRating}
  </span>
)

export default ScoreCardHeadingPill
