import { ReactNode } from "react"
import assert from "assert"
import { Checkbox, useCheckboxState } from "reakit/Checkbox"
import { useField } from "formik"
import cx from "classnames"

export type ValueContentTuple = [string, string | ReactNode]

export interface CheckboxGroupProps {
  valueContentTuples: ValueContentTuple[]
  name: string
  className?: string
  state?: boolean | "indeterminate" | Array<number | string>
}

const CheckboxGroup = ({
  valueContentTuples,
  name,
  className,
  state,
}: CheckboxGroupProps) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")
  const [field] = useField(name)
  const checkbox = useCheckboxState({ state: state })

  return (
    <div
      className={cx("reakit-checkbox-group", className)}
      data-testid="checkbox-group"
    >
      {valueContentTuples.map(([value, content]) => (
        <label
          key={value}
          className="relative z-0 flex flex-row items-center px-2 py-4"
        >
          <Checkbox
            {...field}
            {...checkbox}
            name={name}
            value={value}
            className={cx(
              "border-none relative w-4 peer",
              "hover:border-none focus:border-none active:border-none",
              "hover:ring-transparent focus:ring-transparent active:ring-transparent",
              "checked:border-none checked:bg-transparent checked:rounded-sm checked:bg-checkmark",
              "checked:hover:border-none checked:focus:border-none checked:active:border-none",
              "checked:hover:bg-transparent checked:focus:bg-transparent checked:active:bg-transparent",
              "before:content-[''] before:w-4 before:h-4 before:top-0 before:left-0 before:absolute before:border-2 before:border-charcoal-300",
              "checked:before:content-[''] checked:before:border-grass-900"
            )}
          />

          <span
            className={cx(
              "flex grow pl-2 text-neutral-800 text-base font-normal",
              "peer-checked:relative",
              "peer-checked:before:content-[''] peer-checked:before:absolute peer-checked:before:bg-grass-50",
              "peer-checked:before:-top-4 peer-checked:before:-bottom-4 peer-checked:before:-left-7 peer-checked:before:-right-2 peer-checked:before:-z-1"
            )}
          >
            {content}
          </span>
        </label>
      ))}
    </div>
  )
}

export default CheckboxGroup
