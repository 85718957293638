import ChoiceToggle from "../../components/ChoiceToggle"

interface BecomeAMemberToggleProps {
  show: boolean
  selection: boolean
  setSelection: () => void
}

const BecomeAMemberToggle = ({
  show,
  selection,
  setSelection,
}: BecomeAMemberToggleProps) => {
  if (!show) {
    return null
  }

  return (
    <ChoiceToggle
      selection={selection}
      setSelection={setSelection}
      leftText="Pay Monthly"
      rightText="Pay Yearly"
      additionalText="Save 25%"
      className="mt-3 ml-0"
      leftBtnClassName="member-widget-monthly-membership"
      rightBtnClassName="member-widget-yearly-membership"
    />
  )
}

export default BecomeAMemberToggle
