import React from "react"

interface EligibilityInterestModalStepsListProps {
  steps: string[]
}

const EligibilityInterestModalStepsList = ({
  steps,
}: EligibilityInterestModalStepsListProps) => {
  return (
    <ol className="list-none pl-9 mt-6">
      {steps.map((step, i) => (
        <li key={step} className="relative leading-130 tracking-0.32 mb-6">
          <span className="absolute w-6 h-6 flex items-center justify-center text-leaf text-base rounded-full border border-leaf top-0 left-[-36px]">
            {i + 1}
          </span>

          {step}
        </li>
      ))}
    </ol>
  )
}

export default EligibilityInterestModalStepsList
